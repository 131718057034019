import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import Nav from "react-bootstrap/Nav";
import Card from "react-bootstrap/Card";
import UserDetails from "../../forms/UserDetails";
import {get} from '../../../Services'

const UserManager = (props) => {
    const [view, setView] = useState({form: false, view: true});
    const [data, setData] = useState([]);
    const [editData, setEditData] = useState({});
    useEffect(() => {
        populateData();
    }, []);
    const populateData = () => {
        get("/api/user_details/0/", {}, (resp) => {
            if (resp.status) {
                console.log("data:", resp.data)
                setData(resp.data)
            }
        })
    }
    const changeView = (state) => {
        let cv = {form: false, view: false}
        if (state === 1) {
            cv.form = true
        }
        if (state === 2) {
            populateData()
            cv.view = true
        }

        setView(cv)
    }

    const callback = (action, data) => {
        if (action === 'Edit') {
            get("/api/user_details/" + data.id + "/", {}, (resp) => {
                if (resp.status) {
                    console.log("data i user:", resp.data)
                    setEditData(resp.data)
                    changeView(1)
                }
            })
        }
    }

    const heads = [
        {"house_name": "House Name"},
        {"place": "Place"},
        {"job": "Job"},
        {"educational_qualification": "Qualification"},
    ]
    return (
        <div>
            <Nav className="m-2">
                <Nav className="me-auto"></Nav>
                <Nav className="p-0">
                    <Nav.Link className="p-0">
                        <Button variant="success" size="sm" onClick={() => {
                            changeView(1)
                        }}>Add + </Button>
                    </Nav.Link>
                    <Nav.Link className="p-0">
                        <Button variant="success" size="sm" onClick={() => {
                            changeView(2)
                        }}>View </Button>
                    </Nav.Link>
                </Nav>
            </Nav>
            <div className="overflow-y">
                <Card className="p-2">
                        {view.form && <UserDetails data={editData}/>}
                        {view.view && <BasicTable heads={heads} data={data}
                                                  actions={["View more..", "Edit", "Delete"]}
                                                  actionCallback={callback}/>}

                </Card>
            </div>
        </div>

    );
}
export default UserManager
