import Form from "react-bootstrap/Form";
import React, {useState, useEffect, useRef} from "react";

const FilterBox = (props, ref) => {
    const [value, setValue] = useState([])

    let searchRef = useRef()
    let options = props.options || []
    let onChange = props.onChange || function (value, label) {
    }

    let placeholder = props.placeholder || "Search here"
    let label = props.label || ""
    
    let cursor = 0
    const onKeyUpEvt = (e)=>{
        for(let i = 0; i < ref.current.options.length; i++) {
            let ele = ref.current.options[i]
            if (ele){
                ele.removeAttribute("selected" )
            }
        }
        if (e.keyCode === 38 && cursor > 0) {cursor = cursor - 1}
        else if (e.keyCode === 40 && cursor < options.length - 1) {
            cursor = cursor + 1
        }
        ref.current.selectedIndex = cursor
        let selectOP = ref.current.options[cursor]
        if (selectOP)
            selectOP.setAttribute("selected",'true');

    }
    return (
        <>
            <Form.Group className="mb-1">
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    size="sm"
                    onInput={(e) => {
                        onChange(e.target.value)
                        if (searchRef.current) {
                            setValue(searchRef.current.value)
                        }
                    }}
                    onKeyUp={(e)=>{onKeyUpEvt(e)}}
                    ref={searchRef}

                />
            </Form.Group>
        </>
    )
}
export default React.forwardRef(FilterBox);