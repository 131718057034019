import Navbar from "../../../Navbar";
import {Card, Container, Row, Col, Button, Nav} from "react-bootstrap";
import TextBox from "../../../inputs/TextBox";
import MobileNumber from "../../../inputs/MobileNumber";
import BasicTable from "../../../views/BasicTable";
import React, {useState, useEffect} from 'react';
import DateBox from "../../../inputs/DateBox";
import TextArea from "../../../inputs/TextArea";
import Swal from 'sweetalert2';
import Form from "react-bootstrap/Form";
import {post, get} from '../../../../Services';
import useSharedState from '../../../../ShareSate';
import axios from "axios";
import {useLocation} from 'react-router-dom'
import SelectBox from "../../../inputs/SelectBox";


const TemplateForm = (props) => {//ES6
    const [validated, setValidated] = useState(false);
    const [template, setTemplate] = useState([]);
    const [conf, setConf] = useState([]);
    const {setUser} = useSharedState()
    const location = useLocation()
    const permissions = []
    useEffect(() => {
        getData()
        getConfig()
    }, [])
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false;
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value;
            }
        }
        console.log("data", data)
        post('/api/message/template/',
            data, true, {
                title: 'Success!',
                text: 'Template data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
            getData()
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const getData = () => {
        get("/api/message/template/0/", {}, (resp) => {
            if (resp.status) {
                setTemplate(resp.data)
            }
        })
    }
    const getConfig = () => {
        get("/api/message/conf/0/", {}, (resp) => {
            if (resp.status) {
                setConf(resp.data)
                console.log("config", conf)
            }
        })

    }
    const heads = [
        {"name": "Template Name"},
        {"content": "Template Content"},
    ]
    const callback = (action, data) => {

    }

    return (
        <>
            <Card className="mb-2">
                <Card.Header>Template</Card.Header>
                <Card.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Container className="g-1">
                            <Row className="g-1">
                                <Col md={4} className="g-1">
                                    <SelectBox name="conf" label="Configuration"
                                               options={conf}
                                               keys={{label: "template_id", value: "id"}}/>
                                    <TextBox label="Template Name" name="name"/>
                                    <TextArea label="Message" name="content"/>
                                </Col>
                                <Col md={4} className="g-1">
                                    <Button type="submit" size="sm" className="mt-4">Submit</Button>
                                </Col>
                            </Row>
                        </Container>
                    </Form>
                </Card.Body>

            </Card>
            <Card>
                <Card.Header className="p-1"> <Nav>
                    <Nav className="me-auto px-2"> All Contacts</Nav>
                    <Nav><Button size="sm" className="mx-1">Insert a new field</Button><Button
                        size="sm" className="">Delete a field</Button></Nav>
                </Nav></Card.Header>
                <Card.Body>
                    <BasicTable heads={heads} data={template}
                                actions={permissions}
                                actionCallback={callback}/>
                </Card.Body>
            </Card>
        </>
    )


}
export default TemplateForm