import React,{useState} from 'react';
import Form from "react-bootstrap/Form";
// import validator from 'validator';
function EmailBox(props){
    const [error,setError]=useState("")
    const validate = (e)=> {
        let input = e.target.value;
        
        if (input) {          
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);       
            if (!pattern.test(input)) 
            {  
                setError("Please enter Valid Email Address")
                
                // if(validator.isEmail(!input))
                // {                }
            }          
          }               
    }
    
    return (

        <Form.Group controlId="validationCustom07">
            <Form.Label>Email</Form.Label>
                <Form.Control
                    required
                    tabIndex={8}
                    type="email"
                    placeholder="Enter your Email"
                    size="sm"
                    name="email"
                    onInput={(e) => { validate(e); } }
                />
                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>                          
        );
    }

export default EmailBox;

