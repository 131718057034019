import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import useSharedState from "../../../ShareSate";
import TextBox from '../../inputs/TextBox';
import XForm from "../../inputs/XForm";

const Enquiry = (props) => {
    const formRef = React.createRef()
    const [id, setId] = useState('');
    const [validated, setValidated] = useState(false);
    const [equiry, setEnquiry] = useState([])
    const [shop, setShop] = useState([])
    const [btnValue, setBtnValue] = useState('Add Enquiry');
    const [xdata, setXdata] = useState({});
    const [url, setUrl] = useState("");
    let old = {}

    useEffect(() => {
        getEquiry();
        getShop();
    }, [JSON.stringify(old) != JSON.stringify(equiry)]);
    const handleSubmits = (event, data) => {
        setBtnValue("Add Enquiry")
    }
    const getEquiry = () => {
        get("/api/enquiry/0/", {}, (resp) => {
            if (resp.status) {
                setEnquiry(resp.data);
            }
        });
    }
    const getShop = () => {
        get("/api/company/0/", {}, (resp) => {
            if (resp.status) {
                setShop(resp.data);
            }
        });
    }


    const heads = [
        {"_company.name": "Shop Name",},
        {"name": "Enquiry Name",},
        {"contact_number": "Enquiry Contact"},
        {"number_bottle": "Number of Bottle"},
        {"remarks": "Remarks"}
    ]
    const callback = (actionname, data) => {
        if (actionname === "Edit") {
            setXdata(data)
            setBtnValue("Update Enquiry");
            getEquiry()
        } else if (actionname === "Delete") {
            console.log("delete", actionname);
            del(`api/enquiry/${data.id}`, (resp) => {
                console.log("response", resp);
                getEquiry();
            });
        }
    }
    return (
        <>
            <div>
                <div>
                    <Container className="g-1">
                        <Card className="mb-2">
                            <Card.Header>Enquiry here...</Card.Header>
                            <Card.Body>
                                <Row className="g-1">
                                    <Col md={3} className="g-1">
                                        <XForm url="/api/enquiry/" data={xdata} callback={handleSubmits}>
                                            <SearchBox name="company" label="Enter Shop" options={shop} keys={{label: "name", value: "id"}}/>
                                            <TextBox label="Your Name" name="name"/>
                                            <TextBox label="Your Contact" name="contact_number" required={false}/>
                                            <TextBox label="Remarks" name="remarks"/>
                                            <TextBox label="Number of bottle" name="number_bottle"/>
                                            <Button size="sm" type="submit"
                                                    className="mt-4 mx-2 btn-danger">{btnValue}</Button>
                                        </XForm>
                                    </Col>

                                    <Col md={9} className="g-1 py-4 overflow-y" style={{maxHeight:'80vh'}}>

                                        <BasicTable heads={heads} data={equiry}
                                                    actions={["Edit", "Delete"]}
                                                    actionCallback={callback}/>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>
            </div>
            <div>
            </div>

        </>
    );
}
export default Enquiry
