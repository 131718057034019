import {post,get} from '../../../Services'
import React, {useState,useEffect} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2'
import TextBox from "../../inputs/TextBox";
import BasicTable from "../../views/BasicTable";

const GroupForms = (props) => {
    const [validated, setValidated] = useState(false);
    const [group, setGroup] = useState([]);
    const handleMessageGroup = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false
        }
        console.log(form.elements[0].value)
        console.log(form.elements[0].name)
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
            }

        }
        console.log("sending group data:", data)
        let url = '/api/groupmessage/'
        let is_post = true
        if (props.data.id) {
            url = '/api/groupmessage/' + props.data.id + "/"
            data["user"] = props.data.user
            is_post = false
        }

        post(url,
            data, is_post,
            (resp) => {
                console.log("output:", resp)
                if (resp.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Error occured please do after  sometime' + resp.status,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }

    const getConfig = ()=>{
        get("/api/groupmessage/0/",{},(resp)=>{
            if (resp.status){
                setGroup(resp.data)
            }
        })

    }
    useEffect(()=>{
        getConfig()
    },[])
    const heads = [
        {"group_name": "Group Name"},
    ]
    const callback = (action, data)=>{

    }
    return (
        <div className="card p-2 mt-2">
            <div className="container">

                <Form noValidate validated={validated} onSubmit={handleMessageGroup}>

                    <Row className="mb-3">
                        <Col md={4} className="p-1">
                            <TextBox name="group_name" label="Group Name"/>
                            <Button type="submit" size="sm" className="mt-2">Add New</Button>
                        </Col>
                         <Col md={8} className="p-1">
                             <BasicTable heads={heads} data={group}
                                                  actions={["View more..", "Edit", "Delete"]}
                                                  actionCallback={callback}/>
                         </Col>
                    </Row>


                </Form>
            </div>
        </div>

    );
}
export default GroupForms
