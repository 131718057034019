import {Card, Nav} from "react-bootstrap";
import BasicTable from "../../../views/BasicTable";
import React, {useState, useEffect} from 'react';
import {post, get} from '../../../../Services';
import useSharedState from '../../../../ShareSate';
import {useLocation} from 'react-router-dom'
import FilterBox from "../../../inputs/FilterBox";


const ReportForm = (props) => {//ES6
    const modelRef = React.createRef()
    const [contact, setContact] = useState([]);
    const [contactAlt, setContactAlt] = useState([]);
    useEffect(() => {
        getData();
    }, []);
    
    const getData = () => {
        get("/api/report/0/", {}, (resp) => {
             console.log("Sender Data:", resp.data)
            if (resp.status) {
                setContact(resp.data)
                setContactAlt(resp.data);
            }
        });
    }
    const heads = [
        {"contact.name": "Contact Name"},
        {"contact.number": "Contact Number"},
        {"template.content": "Message"},
        {"group.group_name": "Group"},
        {"status": "Message Sent/Not"},
        {"_date": "Message should be sent at"},
    ]
    const callback = (action, data) => {

    }
    const onchange = (value) => {
        if (value) setContact(contact.filter(i => i.contact.name.toLowerCase().includes(value.toLowerCase())));
        else setContact(contactAlt)
    }

    return (
        <>            
            <Card>

                <Card.Header className="p-1">
                    <Nav className="me-auto px-2"> Reports</Nav>
                </Card.Header>
                <Card.Body>
                <FilterBox ref={modelRef} onChange={onchange}
                                           keys={{label: "name", value: "name"}}
                                           label="Search contact details"/>
                    <BasicTable heads={heads} data={contact} actions={[]}
                                replacer={{status:[<span className="text-success">Message Sent</span>,
                                        <span className="text-info">Pending</span>]}}
                                actionCallback={callback}/>
                </Card.Body>
            </Card>
        </>
    )


}
export default ReportForm