import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import {Row, Col, Container} from 'react-bootstrap'
import BasicTable from "../views/BasicTable";
import Nav from "react-bootstrap/Nav";
import UserDetails from "../forms/UserDetails";
import {get, post} from '../../Services'
import LineChart from "../charts/LineChart";
import SearchBox from "../inputs/SearchBox";
import Swal from "sweetalert2";

const Dashboard = (props) => {
    const modelRef = React.createRef()
    const yRef = React.createRef()
    const xRef = React.createRef()
    const [view, setView] = useState({form: false, view: true});
    const [model, setModel] = useState([]);
    const [data, setData] = useState([]);
    const [xAttr, setXAttr] = useState([]);
    const [yAttr, setYAttr] = useState([]);
    const [editData, setEditData] = useState({});
    useEffect(() => {
        populateModel();
    }, []);
    const populateModel = () => {
        get("/api/dashboard/0/", {}, (resp) => {
            if (resp.status) {
                console.log("data:", resp.data)
                setModel(resp.data)
            }
        })
    }
    const onchange = (value, label) => {
        populateAttr(label)
    }
    const populateAttr = (model) => {
        get(`/api/model_props/${model}/`, {}, (resp) => {
            if (resp.status) {
                console.log("data:", resp.data)
                setXAttr(resp.data.X)
                setYAttr(resp.data.Y)
            }
        })
    }
    const changeView = (state) => {
        let cv = {form: false, view: false}
        if (state === 1) {
            cv.form = true
        }
        if (state === 2) {

            cv.view = true
        }

        setView(cv)
    }
    const populateChart = () => {
        let data = {"model":[modelRef.current.value], "xaxis":[xRef.current.value],"yaxis":[yRef.current.value]}
        console.log(data)
        post("/api/model_data/",
            data, true,
            (resp) => {
                console.log("output:", resp)
                if (resp.status) {
                    setData(resp.data)
                }
            }
        )
    }

    const heads = [
        {"house_name": "House Name"},
        {"place": "Place"},
        {"job": "Job"},
        {"educational_qualification": "Qualification"},
    ]
    console.log(localStorage.getItem("user"))
    return (
        <div>
            <Nav className="m-2" >
                <Nav className="me-auto"></Nav>
                <Nav>
                    <Nav.Link className="p-0">
                        <Button variant="primary" size="sm" onClick={() => {
                            changeView(1)
                        }}>Add + </Button>
                    </Nav.Link>
                    <div className="p-1"></div>
                    <Nav.Link className="p-0">
                        <Button variant="primary" size="sm" onClick={() => {
                            changeView(2)
                        }}>View </Button>
                    </Nav.Link>
                    <div className="p-1"></div>
                    <Nav.Link className="p-0">
                        <Button variant="primary" size="sm" onClick={() => {
                            populateChart()
                        }}>Refresh Chart </Button>
                    </Nav.Link>
                </Nav>
            </Nav>
            <div className="overflow-y">
                <Container className="g-1">
                    <Row className="g-1">
                        <Col md={9}>
                            <div className="card p-2">

                                <Row className="mb-2" className="g-1">
                                    <Col md={6}>

                                        <LineChart x={data.X} y={data.Y}/>

                                    </Col>
                                </Row>
                                <Row className="g-1">
                                    <Col md={6}>
                                        <LineChart/>
                                    </Col>
                                    <Col md={6}>
                                        <LineChart/>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="card p-1">

                                <SearchBox ref={modelRef} multiple onChange={onchange} options={model}
                                           keys={{label: "model", value: "model"}}
                                           label="Search data set"/>
                                <SearchBox ref={xRef} multiple options={xAttr} keys={{label: "label", value: "label"}}
                                           label="Search X Axis Label"/>
                                <SearchBox ref={yRef} multiple options={yAttr} keys={{label: "label", value: "label"}}
                                           label="Search Y Axis Label"/>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>

    );
}
export default Dashboard
