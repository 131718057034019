import React, {useState} from 'react';
import Form from "react-bootstrap/Form";


function MobileNumber(props) {
    const [error, setError] = useState("")
    const validate = (e) => {
        let input = e.target.value;


        if (input) {
            e.target.setCustomValidity("");
            var pattern = new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i);
            if (!pattern.test(input)) {
                e.target.setCustomValidity("Please Enter Number Only");
                setError("Please Enter Number  Only")
            } else if (input.length !== 12) {
                e.target.setCustomValidity("Please enter valid Mobile Number.");
                setError("Please enter valid  Mobile Number")
            }
        }

    }

    return (
        <Form.Group controlId="validationCustom05">
            <Form.Label>{props.label}</Form.Label>
            <Form.Control
                required
                tabIndex={props.tabIndex}
                type="number"
                placeholder={props.placeholder}
                size="sm"
                name={props.name}
                onKeyDown={(e) => {
                    if (e.key!=="Backspace" && e.target.value.length >= 12) e.preventDefault()
                }}
                onInput={(e) => {
                    validate(e);
                }}
            />
            <Form.Control.Feedback type="invalid">{error} </Form.Control.Feedback>
        </Form.Group>

    );
}

export default MobileNumber;