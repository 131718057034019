import './home.css'
import React, {useState, useEffect} from 'react';
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import {Link} from "react-router-dom";

const Feedback = () => {
    const [distributor, setDistributor] = useState([])
    useEffect(() => {
        getDistributer();
    }, []);

    const getDistributer = () => {
        get("/api/anonyfeedback/0/", {}, (resp) => {
            if (resp.status) {
                setDistributor(resp.data);
            }
        });
    }

    return (
        <Container fluid className="g-2">
            <Link to={"/"}>
                <div className={"badge"}> Back</div>
            </Link>
            <h6>All Feedback ({distributor.length})</h6>
            <Row className="g-2">
                {distributor.map((d, i) => {
                    return (<Col md="3" className="g-2 text-white">
                            <div className={"badge"}>

                                <audio controls>
                                    <source src={d.file} type="audio/ogg"/>
                                    <source src={d.file} type="audio/mpeg"/>
                                    Your browser does not support the audio element.
                                </audio>
                            </div>
                        </Col>
                    )
                })
                }
            </Row>

        </Container>
    )
}
export default Feedback

