import Form from "react-bootstrap/Form";
import React from "react";

const TextArea = (props) => {
    const [error,setError] = React.useState('')
    let name = props.name || ""
    let label = props.label || name
    let placeholder = props.placeholder || label ? "Enter " + label : ""
    let message = props.message || placeholder
    let dv = props.defaultValue || ""


    const onlyAlphabets = (e) => {
        var regex = /^[a-zA-Z]*$/;
        if (regex.test(e.target.value)) {
            return true;
        } else {
            setError("Alphabets Only");
            return false;
        }
    }

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                required
                type="textarea"
                placeholder={placeholder}
                size="sm"
                name={name}
                defaultValue={dv}
                onInput={(e)=>{onlyAlphabets(e)}}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}
export default TextArea