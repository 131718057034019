import {useBetween} from "use-between";
import {useState} from "react";
import axios from "axios";

const useFormState = () => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || {});
    localStorage.setItem("user", JSON.stringify(user))
    const [form, setForm] = useState("")
    let isLogged = Object.keys(user).length !== 0
    console.log("Token",user.token)
    if (isLogged) axios.defaults.headers.common['Authorization'] = "Token " + user.token

    if (!user.permissions) {
        user.permissions = [];
    }
    if (!user.nav) {
        user.nav = []
    }

    return {
        user, setUser, form, setForm, isLogged
    };
};
// Make a custom hook for sharing your form state between any components

const useSharedState = () => useBetween(useFormState);
export default useSharedState;