import Navbar from "../../../Navbar";
import {Card, Container, Row, Col, Button, Nav} from "react-bootstrap";
import TextBox from "../../../inputs/TextBox";
import MobileNumber from "../../../inputs/MobileNumber";
import BasicTable from "../../../views/BasicTable";
import React, {useEffect, useState} from "react";
import DateTimeBox from "../../../inputs/DateTimeBox";
import TextArea from "../../../inputs/TextArea";
import SelectBox from "../../../inputs/SelectBox";
import FBNumber from "../../../inputs/FBNumber";
import {get, post} from "../../../../Services";
import useSharedState from "../../../../ShareSate";
import {useLocation} from "react-router";
import Form from "react-bootstrap/Form";

const GroupForm = (props) => {//ES6
    const [validated, setValidated] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [group, setGroup] = useState([]);
    const [contact, setContact] = useState([]);
    const {setUser} = useSharedState()
    const location = useLocation()
    const permissions = []
    useEffect(() => {
        getGroup()
        getContact()
        getSchedule()
    }, [])
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false;
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value;
            }
        }
        console.log("data", data)
        data["uid"] = data.group + "_" + data.template
        post('/api/message/group/contact/',
            data, true, {
                title: 'Success!',
                text: 'Schedule data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
                getSchedule();
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const handleGroup = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false;
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value;
            }
        }
        console.log("data", data)
        post('/api/message/group/',
            data, true, {
                title: 'Success!',
                text: 'Contact data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const getGroup = () => {
        get("/api/message/group/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data);
            }
        });

    }
    const getContact = () => {
        get("/api/message/contact/0/", {}, (resp) => {
            if (resp.status) {
                setContact(resp.data)
            }
        })
    }
    const getSchedule = () => {
        get("/api/message/schedule/0/", {}, (resp) => {
            if (resp.status) {
                setSchedule(resp.data)
            }
        })
    }
    const heads = [
        {"group_name": "Group"},{"desc": "Description"}
    ]
    const callback = (h, g) => {
    }
    return (
        <>

            <Container className="g-1">
                <Row className="g-1">
                    <Col md={6} className="g-1">
                        <Form noValidate validated={validated} onSubmit={handleGroup}>
                            <Card className="mb-2">
                                <Card.Header>Group</Card.Header>
                                <Card.Body>
                                    <TextBox label="Group Name" name="group_name"/>
                                    <TextBox label="Description" name="desc"/>
                                    <TextBox label="Upload Contacts" name="field"/>
                                    <Button size="sm" type="submit" className="mt-4">Submit</Button>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                    {/*<Col md={6} className="g-1">*/}
                    {/*    <Card className="mb-2">*/}
                    {/*        <Card.Header>Add Contact To Group</Card.Header>*/}
                    {/*        <Card.Body>*/}
                    {/*            <Form noValidate validated={validated} onSubmit={handleSubmit}>*/}
                    {/*                <SelectBox name="contact" label="Contact"*/}
                    {/*                           options={contact}*/}
                    {/*                           keys={{label: "name", value: "id"}}/>*/}
                    {/*                <SelectBox name="group" label="Group Name"*/}
                    {/*                           options={group}*/}
                    {/*                           keys={{label: "group_name", value: "id"}}/>*/}
                    {/*                <Button type="submit" size="sm" className="my-2">Submit</Button>*/}
                    {/*            </Form>*/}
                    {/*        </Card.Body>*/}

                    {/*    </Card>*/}
                    {/*</Col>*/}

                </Row>
            </Container>

            <Card>
                <Card.Header className="p-1">
                    <Nav>
                        <Nav className="me-auto px-2"> All Groups</Nav>
                        <Nav><Button size="sm" className="mx-1">Insert a new field</Button><Button
                            size="sm" className="">Delete a field</Button></Nav>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    <BasicTable heads={heads} data={group}
                                actions={["View more..", "Edit", "Delete"]}
                                actionCallback={callback}/>
                </Card.Body>
            </Card>
        </>
    )
}
export default GroupForm