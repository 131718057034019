import {Form, Button, ButtonGroup} from "react-bootstrap";
import React from "react";

const FBNumber = (props) => {
    const [error, setError] = React.useState('')
    let name = props.name || ""
    let label = props.label || name
    let placeholder = props.placeholder || label ? "Enter " + label : ""
    let message = props.message || placeholder
    let dv = props.defaultValue || ""


    const onlyAlphabets = (e) => {
        var regex = /^[a-zA-Z]*$/;
        if (regex.test(e.target.value)) {
            return true;
        } else {
            setError("Alphabets Only");
            return false;
        }
    }

    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <div>
                <ButtonGroup size="sm">
                    <Button size="sm">-</Button>
                    <Form.Control
                        required
                        type="text"
                        placeholder={placeholder}
                        size="sm"
                        name={name}
                        defaultValue={0}
                        style={{width: 30}}
                    />
                    <Button size="sm">+</Button>
                </ButtonGroup>
            </div>
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}
export default FBNumber