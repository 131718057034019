import axios from "axios";
import Swal from 'sweetalert2';


const saveSession = (session) => {
    localStorage.setItem("session", JSON.stringify(session))
}
const getSession = (session) => {
    return JSON.parse(localStorage.getItem("session")) | {}
}
const clearSession = (session) => {
    saveSession({})
}

// ------------------------------------------------------------------------
const post = (url, data, is_post = 0, need_success_message = false, need_failed_message = false, callback) => {

    if (is_post) {
        let error = need_failed_message? need_failed_message:()=>{};
        axios.post(url, data)
            .then(function (response) {
                if (need_success_message) {
                    Swal.fire(need_success_message);
                }
                callback(response);
            })
            .catch(function (error) {
                console.log("ERROR:", error)
                let messages = "";
                Object.keys(error.response.data).map((key, i) => {
                    if (error.response.data[key].constructor.name === 'Array') {
                        messages += error.response.data[key].join("/n");
                    } else {
                        messages += error.response.data[key];
                    }
                    messages = messages.replace("_", " ");
                })
                console.log("error:", messages);
                Swal.fire({
                    title: 'Failed',
                    text: messages,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            });
    } else {
        axios.put(url, data)
            .then(function (response) {
                if (need_success_message) {
                    Swal.fire(need_success_message);
                }
                callback(response)
            })
            .catch(function (error) {
                console.log("ERROR:", error)
                let messages = error.response.data.non_field_errors.join("/n")
                console.log("error:", messages);
                Swal.fire({
                    title: 'Failed',
                    text: messages,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });

            });
    }
}

const get = (url, data, callback) => {
    axios.get(url, data)
        .then(function (response) {
            callback(response)
        })
        .catch(function (error) {
            console.log("error:", error)
            callback(error)
        });
}
const del = (url, callback) =>{
    axios.delete(url)
        .then(function (response) {
            callback(response)
        })
        .catch(function (error) {
            console.log("error:", error)
            callback(error)
        });
}
const login = (url, data, callback) => {
    post(url, data, false, false, false, (res) => {
        if (res.status) {
        }
    });
}
export {saveSession, getSession, clearSession, post, get, del, login}
