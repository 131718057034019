import React, {useState, useEffect} from 'react';
import {get, post, del} from '../../Services'
import Form from "react-bootstrap/Form";

const XForm = (props) => {
    const formRef = React.createRef()
    const [id, setId] = useState('');
    const [validated, setValidated] = useState(false);
    let defCall = props.defSubmit || true
    let saveBtn = props.saveBtn || "Save"
    let updateBtn = props.updateBtn || "Update"
    let hasFile = props.hasFile || false
    let successMessage = props.message || "Successfully updated"
    useEffect(() => {
        setId('')
        refreshForm(props.data || {})
    }, [props.data])

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            return false;
        }
        let data = {}
        let formData = new FormData()

        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                if (form.elements[i].type === "file") {
                    if (form.elements[i].files.length !== 0) {
                        formData.append(form.elements[i].name, form.elements[i].files[0]);
                    }
                } else {
                    formData.append(form.elements[i].name, form.elements[i].value);
                }
                data[form.elements[i].name] = form.elements[i].value;
                if (props.hasmulti && form.elements[i].tagName === 'SELECT' && props.hasmulti.indexOf(form.elements[i].name) > -1) {
                    data[form.elements[i].name] = Array.from(form.elements[i].selectedOptions, (option) => option.value);
                    formData.append(form.elements[i].name, Array.from(form.elements[i].selectedOptions, (option) => option.value));
                }
            }
        }


        //find out need save or update the data
        if (defCall) {
            console.log("data", data)
            let url = props.url
            let is_post = true
            if (id && id !== '') {
                data["id"] = id
                url = `${url}${data["id"]}/`
                is_post = false
            }
            setId('');
            data = hasFile ? formData : data
            post(url,
                data, is_post, {
                    title: 'Success!',
                    text: successMessage,
                    icon: 'success',
                    confirmButtonText: 'OK'
                }, false,
                (resp) => {

                })
            setValidated(false);
            if (props.callback) {
                props.callback(event, data);
            }
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (props.callback) {
                props.callback(event, data);
            }
        }

    }
    const refreshForm = (data) => {
        if (data && data.length !== 0) {
            let form = formRef.current
            for (let i in form.elements) {
                if (form.elements[i].name !== undefined && data[form.elements[i].name] && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                    if (form.elements[i].type === "file") {
                    } else {
                        form.elements[i].value = data[form.elements[i].name];
                    }
                }
            }
            setId(data["id"]);
        } else {
            setId('');
        }
    }
    return (
        <Form noValidate ref={formRef} validated={validated} onSubmit={handleSubmit}>
            {props.children}
        </Form>
    );
}
export default XForm
