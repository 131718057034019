import Form from "react-bootstrap/Form";
import React, {useState, useEffect, useRef} from "react";

const SearchBox = (props, ref) => {
    const [value, setValue] = useState([])
    let hideDropDown = props.hideDropDown?false : true
    let searchRef = useRef()
    let options = props.options || []
    let onchange = props.onChange || function (value, label) {
    }
    let onselect = props.onSelect || function (value, label) {
    }
    let keys = props.keys || {label: "label", value: "value"}
    let multiple = props.multiple || false
    let name = props.name || ""
    let placeholder = props.placeholder || "Search here"
    let label = props.label || ""
    const onChangeValue = (selector) => {
        searchRef.current.value = selector.options[selector.selectedIndex].text
        onchange(selector.value, searchRef.current.value)
    }

    let cursor = 0
    const onKeyUpEvt = (e) => {
        if (!hideDropDown) return
        for (let i = 0; i < ref.current.options.length; i++) {
            let ele = ref.current.options[i]
            if (ele) {
                ele.removeAttribute("selected")
            }
        }
        if (e.keyCode === 38 && cursor > 0) {
            cursor = cursor - 1
        } else if (e.keyCode === 40 && cursor < options.length - 1) {
            cursor = cursor + 1
        }
        ref.current.selectedIndex = cursor
        let selectOP = ref.current.options[cursor]
        if (selectOP)
            selectOP.setAttribute("selected", 'true')

    }
    return (
        <>
            <Form.Group className="mb-1">
                <Form.Label>{label}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={placeholder}
                    size="sm"
                    onKeyDown={(e) => {
                        if (searchRef.current) {
                            setValue(searchRef.current.value)
                        }
                    }}
                    onKeyUp={(e) => {
                        onKeyUpEvt(e)
                    }}
                    ref={searchRef}

                />
            </Form.Group>
            {hideDropDown===true && <Form.Select ref={ref} size="sm" name={name} multiple={multiple} onChange={(value) => {
                onChangeValue(value.target)
            }}>
                <option value="0">{label}</option>
                {
                    options.map((option, i) => {
                        let op = true
                        if (searchRef && searchRef.current && searchRef.current.value)
                            op = searchRef.current && option[keys.label].toLowerCase().includes(searchRef.current.value.toLowerCase())
                        return op && <option onClick={(e) => {
                            onselect(e.target.value, e.target.text)
                        }} key={i} value={option[keys.value]}> {option[keys.label]}</option>
                    })
                }
            </Form.Select>}
        </>
    )
}
export default React.forwardRef(SearchBox);