import {Link} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import useSharedState from "../ShareSate";

const NavbarTop = (props) => {
    const {user} = useSharedState()
    let first_name = user.first_name || ""
    let last_name = user.last_name || ""
    return (
        <Nav className={props.className} style={{padding:'2px'}}>

            <Nav defaultActiveKey="/home" className="me-auto">
                <header className="navbar-header">
                    <a href="#" className="brand-logo">NELLIKKA KANTHARI</a>
                </header>
            </Nav>
            <Nav>
                <span className="mx-2">{first_name} {last_name}</span>
                <Link to="/profile">
                    <div className="rounded-circle bg-light" style={{'width': '21px', 'height': '21px'}}
                         title="Profile"></div>
                </Link>
            </Nav>
        </Nav>
    )
}
export default NavbarTop
