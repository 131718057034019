import {post} from '../../Services'
import React, {useState} from 'react';
import axios from "axios";
import Form from "react-bootstrap/Form";
import {Row, Container} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Swal from "sweetalert2";
import useSharedState from "../../ShareSate";
import {useNavigate} from 'react-router-dom';
import TextBox from "../inputs/TextBox";
import PasswordBox from "../inputs/PasswordBox";
import Card from 'react-bootstrap/Card';
import {Link} from 'react-router-dom';


const Login = () => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate()
    const {setUser} = useSharedState()
    const handleLogin = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
            return 0
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
            }

        }
        console.log("Request Data:", data)
        axios.defaults.headers.common['Authorization'] = ""
        post('/api/token/',
            data, true,false,false,
            (resp) => {
                if (resp.status) {
                    axios.defaults.headers.common['Authorization'] = "Token " + resp.data.token
                    setUser(resp.data)
                    navigate('/user_details')
                }
            })
        event.preventDefault();
        event.stopPropagation();
    }
    return (

        <Form noValidate validated={validated} onSubmit={handleLogin}>
            <Row className="mb-1">
                <TextBox name="username"/>
                <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type="text"
                        placeholder="Enter password"
                        size="sm"
                        name="password"

                    />
                    <Form.Control.Feedback type="invalid">Password required</Form.Control.Feedback>
                </Form.Group>
            </Row>
            <div><center>
                <Button type="submit" variant="info" className="b p-1" size="md">Login</Button></center>
                {/* <Link to="/user_add" className="p-1 py-3"> Or <u><i>register here</i></u></Link> */}
            </div>
        </Form>


    );
}
export default Login
