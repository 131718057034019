import {Card, Container, Row, Col, Button, Nav} from "react-bootstrap";
import FileUpload from "../../../fileupload/FileUpload";
import React, {useEffect, useState} from 'react';
import Form from "react-bootstrap/Form";
import {get, post} from '../../../../Services';
import useSharedState from "../../../../ShareSate";
import BasicTable from "../../../views/BasicTable";
import SelectBox from "../../../inputs/SelectBox";

const UploadForm = (props) => {//ES6
    const [contact, setConatct] = useState([]);
    const [group, setGroup] = useState([]);
    useEffect(() => {
        getContacts();
        getGroup();
    }, [])
    const getContacts = () => {
        get("/api/message/contact/0/", {}, (resp) => {
            if (resp.status) {
                console.log("contats", resp.data)
                setConatct(resp.data)
            }
        })
    }
    const getGroup = () => {
        get("/api/message/group/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data)
            }
        })

    }
    const callback = (action, data) => {
        console.log(action, data)
        post('/api/xlupload/',
            data, true, {
                title: 'Success!',
                text: 'Contact data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
                if (resp.status) {
                    getContacts()
                    console.log("datafrom server", resp.data)
                }
            });
    }

    const heads = [
        {"name": "Name"}, {"number": "Number"}, {"details._date": "Date"}, {"details.type": "Message Type"}
    ]
    return (
        <>
            <Card className="mb-2">
                <Card.Header>Upload XL</Card.Header>
                <Card.Body>
                    <Form noValidate>
                        <Container className="g-1">
                            <Row className="g-1">
                                <Col md={4} className="g-1">
                                    <SelectBox label="Select Group"  name="group_id" options={group}
                                               keys={{label: "group_name", value: "id"}}/>
                                    <p></p>
                                    <FileUpload callback={callback}
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                                </Col>
                                <Col md={8} className="g-1 pt-5 text-center">
                                    <a className="text-center">Download Excel Format</a>
                                </Col>

                            </Row>
                        </Container>
                    </Form>
                </Card.Body>

            </Card>
            <Card>
                <BasicTable heads={heads} data={contact}
                            actions={[]}
                            actionCallback={callback}/>
            </Card>

        </>
    )


}
export default UploadForm