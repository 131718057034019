import Navbar from "../../../Navbar";
import {Card, Container, Row, Col, Button, Nav} from "react-bootstrap";
import TextBox from "../../../inputs/TextBox";
import MobileNumber from "../../../inputs/MobileNumber";
import BasicTable from "../../../views/BasicTable";
import React, {useEffect, useState} from "react";
import DateTimeBox from "../../../inputs/DateTimeBox";
import TextArea from "../../../inputs/TextArea";
import SelectBox from "../../../inputs/SelectBox";
import FBNumber from "../../../inputs/FBNumber";
import {get, post} from "../../../../Services";
import useSharedState from "../../../../ShareSate";
import {useLocation} from "react-router";
import Form from "react-bootstrap/Form";

const ScheduleForm = (props) => {//ES6
    const [validated, setValidated] = useState(false);
    const [schedule, setSchedule] = useState([]);
    const [group, setGroup] = useState([]);
    const [template, setTemplate] = useState([]);
    const {setUser} = useSharedState()
    const location = useLocation()
    const permissions = []
    useEffect(() => {
        getGroup()
        getTemplate()
        getSchedule()
    }, [])
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false;
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value;
            }
        }
        console.log("data", data)
        data["uid"] = data.group + "_" + data.template
        post('/api/message/schedule/',
            data, true, {
                title: 'Success!',
                text: 'Schedule data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
                getSchedule();
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const getGroup = () => {
        get("/api/message/group/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data);
            }
        });

    }
    const getTemplate = () => {
        get("/api/message/template/0/", {}, (resp) => {
            if (resp.status) {
                setTemplate(resp.data)
            }
        })
    }
    const getSchedule = () => {
        get("/api/message/schedule/0/", {}, (resp) => {
            if (resp.status) {
                setSchedule(resp.data)
            }
        })
    }
    const heads = [
        {"templates.name": "Name"}, {"groups.group_name": "Group"}
    ]
    const callback = (h, g) => {
    }
    return (
        <>
            <Card className="mb-2">
                <Card.Header>Schedule</Card.Header>
                <Card.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Container className="g-1">
                            <Row className="g-1">
                                <Col md={4} className="g-1">
                                    <SelectBox name="template" label="Wishing Template"
                                               options={template}
                                               keys={{label: "name", value: "id"}}/>
                                    <DateTimeBox label="Select Date" name="schedule"/>
                                </Col>
                                <Col md={4} className="g-1">
                                    <SelectBox name="group" label="Wishing Type/(Group Name)"
                                               options={group}
                                               keys={{label: "group_name", value: "id"}}/>
                                    <Row className="g-0">
                                        <Col md={6} className="g-0">
                                            <FBNumber label="Days"/>
                                        </Col>
                                        <Col md={6} className="g-0 pt-4">
                                            <Button type="submit" size="sm">Submit</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Container>
                    </Form>
                </Card.Body>

            </Card>
            <Card>
                <Card.Header className="p-1">
                    <Nav>
                        <Nav className="me-auto px-2"> All Contacts</Nav>
                        <Nav><Button size="sm" className="mx-1">Insert a new field</Button><Button
                            size="sm" className="">Delete a field</Button></Nav>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    <BasicTable heads={heads} data={schedule}
                                actions={["View more..", "Edit", "Delete"]}
                                actionCallback={callback}/>
                </Card.Body>
            </Card>
        </>
    )
}
export default ScheduleForm