import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import { FcCheckmark } from "react-icons/fc";
import BasicTable from "../../views/BasicTable";
import Nav from "react-bootstrap/Nav";
import UserDetails from "../../forms/UserDetails";
import {get, post} from '../../../Services'
import {Row, Col, Container, Card} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import Badge from 'react-bootstrap/Badge';
import SelectBox from "../../inputs/SelectBox";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import ErrorBoundary from "../../errorcatch/ErrorBoundary";
import useSharedState from "../../../ShareSate";
import ConfigForms from "./ConfigForms";
import TemplateForms from "./TemplateForms";
import GroupForms from "./GroupForms";
import ScheduleForms from "./ScheduleForms";
import Forms from "./Forms";
import {forEach} from "react-bootstrap/ElementChildren";

const Manager = (props) => {
    const [view, setView] = useState(localStorage.message_view);
    const {user} = useSharedState()
    const [permissions, setPermissions] = useState([])
    const [usergroup, setUsergroup] = useState([])
    const getUserGroup = () => {
        get("/api/groupusermessage/0/", {}, (resp) => {
            if (resp.status) {
                console.log(resp.data)
                setUsergroup(resp.data)
            }
        })

    }

    const changeView = (state) => {
        localStorage.group_view = state
        setView(state)
    }
    useEffect(() => {
        getUserGroup()
        setPermissions(user.permissions.filter((per) => {
            return per.startsWith('message.')
        }))
        changeView(localStorage.group_view)
    }, [])
    const heads = [
        {"group.group_name": "Group"},
        {"user.first_name": "User"},
        {"template.content": "Template"},
        {"schedule": "Scheduled Date"},
        {"status": "Status"},
    ]
    const callback = (action, data) => {

    }
    return (
        <div>
            <Nav>
                <Nav className="me-auto">
                    {permissions.map((perm, i) => {
                        if (perm.startsWith("message.add_")) {
                            if (!localStorage.group_view && i === 0) {
                                localStorage.message_view = perm;
                                changeView(perm)
                            }
                            let key = perm.split(".")[1].replace("_", " ")
                            return <a href="#" key={i} style={{textTransform: 'capitalize'}}>
                                <Badge
                                    className={view === perm ? "bg-light text-success mx-1" : "bg-light text-primary mx-1"}
                                    onClick={() => {
                                        changeView(perm)
                                    }}>{key}</Badge>
                            </a>
                        }
                    })}
                    <Badge
                        className={view === 'message.view_message' ? "bg-light text-success mx-1" : "bg-light text-primary mx-1"}
                        onClick={() => {
                            changeView("message.view_message")
                        }}>View Message</Badge>
                </Nav>
                <Nav>

                </Nav>
            </Nav>
            <div>
                {view === 'message.add_messageconf' && <Container className="g-1">
                    <ConfigForms data={{}}/>
                </Container>}
                {view === 'message.add_templatemessage' && <Container className="g-1">
                    <TemplateForms data={{}}/>
                </Container>}
                {view === 'message.add_groupmessage' && <Container className="g-1">
                    <GroupForms data={{}}/>
                </Container>}
                {view === 'message.add_groupusermessage' && <Container className="g-1">
                    <ScheduleForms data={{}}/>
                </Container>}

                {view === 'message.add_message' &&
                <Container className="g-1">
                    <Forms data={{}}/>
                </Container>}
                {view === 'message.view_message' &&
                <Container className="g-1">
                    <Card className="p-2 mt-2">
                        <BasicTable heads={heads} data={usergroup} replacer={{"status":[<FcCheckmark/>]}}
                                    actions={["View more..", "Edit", "Delete"]}
                                    actionCallback={callback}/>
                    </Card>
                </Container>}


            </div>
        </div>

    );
}
export default Manager
