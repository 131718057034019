import React,{useState} from 'react';
import Form from "react-bootstrap/Form";
import validator from 'validator';
var FontAwesome = require('react-fontawesome')

function PasswordBox(props){
    const [error,setError]=useState("")
    const [passwordShown, setPasswordShown] = useState(false);

    const togglePassword = () =>{
        setPasswordShown(!passwordShown);
    }
    const validate = (e)=> {
        let input = e.target.value;
        
        if(!validator.isStrongPassword(input,{minLength:8,minLowercase:1,minUppercase:1,minNumbers:1,minSymbols:1,maxLength:16}))
        {
            e.target.setCustomValidity("Weak Password");
            setError("Weak Password")

        } else {
            e.target.setCustomValidity("");
            setError("Strong Password")
        }
    }
                       
    
    return ( 
    
        <Form.Group  controlId="validationCustom02">
                    <Form.Label>{props.Label}</Form.Label>
                    <Form.Control
                        required
                        type={passwordShown ? "text" : "password"}  

                        i = "far fa-eye" onClick={togglePassword}
                        // {/* // i class="far fa-eye" */}
                        // div className="text-2x1 absolute top-1 right-5"
                        tabIndex={props.tabIndex}
                        placeholder={props.placeholder}
                        size="sm"
                        name={props.name}
                        onInput={(e) => { validate(e); }}
                    />
                    <Form.Control.Feedback>
    {/* <span style={{ top: '5px' }}>
        <FontAwesome name="check" spin key="icon" />
    </span> */}
</Form.Control.Feedback>
                   <Form.Control.Feedback type="invalid"> {error} </Form.Control.Feedback>
        </Form.Group>
            )
}
export default PasswordBox;
            