import './home.css'
import BasicTable from "../../views/BasicTable";
import SelectBox from "../../inputs/SelectBox";
import TextBox from "../../inputs/TextBox";
import CarosalEx from "./CarosalEx";
import img_01 from './01.PNG';
import img_02 from './02.PNG';
import img_03 from './03.PNG';
import bg from './bg.PNG';
import map from './map.png';
import label from './label.png';
import React, {useState, useEffect} from 'react';
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
import {AiFillPhone} from "react-icons/ai";
import {Link} from 'react-router-dom';

const Home = () => {
    const [xdata, setXdata] = useState({});
    const [district, setDistrict] = useState([])
    const [company, setCompany] = useState([])
    const [distributor, setDistributor] = useState([])
    const [filtcompany, setFiltCompany] = useState([])
    const [userCount, setUserCount] = useState(0)
    const [showCompany, setShowCompany] = useState(false)
    const [total, setTotal] = useState(0)
    const [latlng, setLatlng] = useState({"lat":0, "lng":0})

    const distRef = React.createRef()
    const searchRef = React.createRef()

    useEffect(() => {

        getDistrict();
        getDistributer();
        getLocation()

    }, []);

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                let lat = parseFloat(position.coords.latitude).toFixed( 2 )
                let lng = parseFloat(position.coords.longitude).toFixed( 2 )
                setLatlng({"lat":lat,"lng":lng})
                countUser(position.coords.latitude, position.coords.longitude)
            }, (err) => {
                countUser(0, 0);
            });
        } else {
            countUser(0, 0);
        }
    }

    const countUser = (lat, lng) => {
        let data = {"old_user": localStorage.oldUser, lat: lat, lng: lng}
        post("api/usercount/",
            data, true, false, false,
            (resp) => {
                localStorage.oldUser = true
                setUserCount(resp.data.user_count)
            })
    }
    const getDistributer = () => {
        get("/api/anonydistributor/0/", {}, (resp) => {
            if (resp.status) {
                setDistributor(resp.data);
            }
        });
    }
    const getDistrict = () => {
        get("/api/anonydistrict/0/", {}, (resp) => {
            if (resp.status) {
                setDistrict(resp.data);
                let t = 0
                district.map((row, i) => {
                    t = t + row._counts[row.id]
                });
                setTotal(t)
            }
        });
    }
    const getCompany = (id) => {
        setShowCompany(true)
        get(`api/anonycompanybydist/${parseInt(id)}/`, {}, (resp) => {
            if (resp.status) {
                setCompany(resp.data);
                setFiltCompany(resp.data);
            }
        });
    }
    const shopFilter = (e) => {
        let search = (e.target.name !== "district" && e.target.value) ? e.target.value : "";

        if (search.length > 0) {
            setFiltCompany(company.filter((i) => {
                let allText = i.name + i.place
                return allText.toLowerCase().includes(search.toLowerCase());
            }));

        } else {
            setFiltCompany(company)
        }
    };
    let heads = [
        {"_district.name": "District"},
        {"name": "Shop Name"},
        {"contact": "Phone Number"},
        {"place": "Place"},
        {"address": "Address"}]

    return (
        <Container fluid className="g-2">
            <Row className="g-2">
                <Col md="9" className="g-2">

                    {!showCompany && <Row className="g-2">
                        <Col md="10" className="g-2">
                            <div className="overflow-hidden" style={{maxHeight: "400px"}}>
                                <Image src={bg} width="100%"/>
                            </div>
                        </Col>
                        <Col md="2" className="text-center">

                            <div style={{
                                margin: "auto",
                                fontSize: "14px",
                                padding: "12px",
                                borderRadius: "80px",
                                width: "60px",
                                height: "60px",
                                border: "3px solid green",
                                textAlign: "center",
                                verticalAlign: "30%",
                                color: "white"
                            }}>
                                {userCount}
                                <div style={{fontSize: "8px"}}><b>Visitors</b></div>

                            </div>
                            <div style={{textAlign:"center", fontSize:"10px"}}>{latlng.lat}, {latlng.lng}</div>
                        </Col>

                    </Row>}
                    <Row className="g-2">

                        <Col md="4" className="g-2">
                            <Card className="p-1 my-2">
                                <Card.Body className="p-1  text-center sks-bg-yellow"><b>HOW TO USE</b></Card.Body>
                            </Card>
                        </Col>
                        <Col md="4" className="g-2">
                            <Card className="p-1 my-2">
                                <Link to={"/feedback"}><Card.Body
                                    className="p-1 text-center sks-bg-yellow"><b>FEEDBACKS</b></Card.Body></Link>
                            </Card>

                        </Col>
                        <Col md="2" className="g-2">
                            <Card className="p-1 my-2">
                                <Link to={"/distributors"}><Card.Body
                                    className="p-1 text-center sks-bg-yellow"><b>DISTRIBUTORS</b></Card.Body></Link>
                            </Card>

                        </Col>
                        <Col md="2" className="g-2">

                        </Col>
                    </Row>
                    <Row className="g-2">
                        <Col md="10" className="g-2">
                            <Container fluid className="g-2">
                                {!showCompany && <Row className="g-2">
                                    <Col md="12" className="g-2  my-4 mb-1 text-danger text-center">
                                        <h6 style={{marginBottom: '2px'}}>SELECT YOUR DISTRICT</h6>
                                        <div style={{fontSize: 14}}>
                                            <b>Visit our connected shop, You can buy from here</b>
                                        </div>
                                    </Col>
                                </Row>}
                                {!showCompany && <Row className="g-2 mt-1">
                                    {

                                        district.map((row, i) => {
                                            if (row.name === 'Dont Know') return undefined
                                            return (
                                                <Col md="4" className="g-2" key={i}>
                                                    <Card className="p-1 my-2 btn">
                                                        <Card.Body className="p-1 sks-bg-green">
                                                            <div
                                                                className="py-0 text-center">
                                                                <div className="btn-group" style={{width: '100%'}}>
                                                                    <span className=""
                                                                          aria-current="page" style={{
                                                                        width: '100%',
                                                                        borderRight: "1px solid white"
                                                                    }}>
                                                                        <div style={{cursor: 'pointer',}}
                                                                             onClick={() => {
                                                                                 getCompany(row.id)
                                                                             }}>
                                                                        <div>View Shops</div>
                                                                        <b style={{
                                                                            textTransform: 'uppercase',
                                                                            color: "white"
                                                                        }}>{row.name}</b>
                                                                        </div>
                                                                            <div><a className="text-primary"
                                                                                  href={`tel:${row.contact}`}><AiFillPhone/> {row.contact}
                                                            </a></div>
                                                                    </span>
                                                                    <span style={{width: '30%', fontSize: '16px'}}>
                                                                        <b style={{fontSize: '18px'}}>{row._counts[row.id]}</b>
                                                                        <div>Shops</div>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>}
                                {showCompany && <Row className="g-2 mt-1">
                                    <Col md="10" className="g-2 ">
                                        <a className=" text-primary btn btn-warning"
                                           style={{cursor: "pointer", color: "white!important"}} onClick={() => {
                                            setShowCompany(false)
                                        }}>Back</a><span style={{
                                        marginLeft: '10px',
                                        float: 'right'
                                    }}>Total shops {filtcompany.length}</span>
                                        <br/>
                                        <label>Search Place</label>
                                        <input className="form-control" style={{height: 30}}
                                               onInput={shopFilter} placeholder="Search here..."/>
                                    </Col>
                                    {
                                        filtcompany.map((row, i) => {
                                            if (row.name === 'Dont Know') return undefined
                                            return (
                                                <Col md="4" className="g-2" key={i}>
                                                    <Card className="p-2 my-2">
                                                        {/*<Card.Header style={{*/}
                                                        {/*    fontSize: '13px',*/}
                                                        {/*    padding: '4px',*/}
                                                        {/*    color: "white"*/}
                                                        {/*}}>{row.place}</Card.Header>*/}
                                                        <Card.Body className="p-1">
                                                            <div className="py-0">Shop name : {row.name}</div>
                                                            <hr/>
                                                            <div className="py-1 text-danger">Shop place
                                                                : {row.place}</div>
                                                            <div className="py-0">Address : {row.address}</div>
                                                            <div className="py-0"><a className="text-primary"
                                                                                     href={`tel:${row.contact}`}><AiFillPhone/> {row.contact}
                                                            </a>
                                                            </div>
                                                            <div className="text-lg-end">
                                                                {(row.location && row.location != 'NA') &&
                                                                <a href={row.location}>Go to location <Image width={35}
                                                                                                             src={map}/></a>}
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                    {filtcompany.length == 0 && <div className="text-danger">No shop available</div>}
                                </Row>}

                            </Container>
                        </Col>
                    </Row>

                </Col>
                <Col md="3" className="g-2">


                </Col>
            </Row>

        </Container>
    )
}
export default Home

