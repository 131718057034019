import {post, get} from '../../../Services'
import React, {useState, useEffect} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Stack from 'react-bootstrap/Stack';
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2'
import SearchBox from "../../inputs/SearchBox";
import SelectBox from "../../inputs/SelectBox";

const ScheduleForms = (props) => {
    const tempateRef = React.createRef()
    const groupRef = React.createRef()
    const dateRef = React.createRef()
    const [validated, setValidated] = useState(false);
    const [template, setTemplate] = useState([]);
    const [user, setUser] = useState([]);
    const [group, setGroup] = useState([]);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false
        }
        console.log(form.elements[0].value)
        console.log(form.elements[0].name)
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
                if (form.elements[i].tagName === 'SELECT') {
                    data[form.elements[i].name] = Array.from(form.elements[i].selectedOptions, (option) => option.value)
                }
            }

        }
        console.log("sending group data:", data)
        let url = '/api/schedule/'
        let is_post = true
        if (props.data.id) {
            url = '/api/schedule/' + props.data.id + "/"
            data["user"] = props.data.user
            is_post = false
        }

        post(url,
            data, is_post,
            (resp) => {
                console.log("output:", resp)
                if (resp.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Error occured please do after  sometime' + resp.status,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }

    const getGroup = () => {
        get("/api/message/group/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data)
            }
        })

    }
    const getTemplate = () => {
        get("/api/messagetemplate/0/", {}, (resp) => {
            if (resp.status) {
                setTemplate(resp.data)
            }
        })

    }
    const getUser = () => {
        get("/api/user/0/", {}, (resp) => {
            if (resp.status) {
                setUser(resp.data)
            }
        })

    }
    useEffect(() => {
        getTemplate()
        getGroup()
        getUser()
    }, [])
    const heads = [
        {"group_name": "Group Name"},
    ]
    let date = ""
    let time = ""
    return (
        <div className="card p-2 mt-2">
            <div className="container">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md={4} className="p-1">
                            <SelectBox name="group" label="Wishing Type/(Group Name)"
                                       options={group}
                                       keys={{label: "group_name", value: "id"}}/>
                            <p className="mt-1 mb-1">Templates</p>
                            <input name="template" type="hidden" ref={tempateRef}/>

                            <div className="overflow-auto" style={{maxHeight: "60vh"}}>
                                {
                                    template.map((temp, i) => {
                                        return (
                                            <Card className="p-1 mt-1" key={i}>
                                                <Stack direction="horizontal" gap={3}>
                                                    <div>
                                                        <h6>{temp.name}</h6>
                                                        <p>{temp.content}</p>
                                                    </div>
                                                    <div className="ms-auto">
                                                        <Form.Check
                                                            type="radio"
                                                            name="template_radio"
                                                            value={temp.id}
                                                            onClick={() => {
                                                                tempateRef.current.value = temp.id
                                                            }}
                                                        />
                                                    </div>

                                                </Stack>
                                            </Card>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                        <Col md={4} className="p-1">
                            <SearchBox name="users" className="mt-1" label="Select/Enter User" options={user}
                                       keys={{label: "first_name", value: "id"}}
                                       multiple/>
                        </Col>
                        <Col md={4} className="p-1  ">
                            <input name="schedule" type="hidden" ref={dateRef}/>
                            <Form.Group controlId="validationCustom03">
                                <Form.Label>Date</Form.Label>
                                <Form.Control
                                    type="date"
                                    size="sm" onChange={(e) => {
                                    dateRef.current.value = e.target.value + (time !== "" ? " " + time : "");
                                    date = e.target.value
                                }}/>
                            </Form.Group>
                            <Form.Group controlId="validationCustom03">
                                <Form.Label>Time</Form.Label>
                                <Form.Control type="time" size="sm" onChange={(e) => {
                                    dateRef.current.value = date !== "" ? date + " " + e.target.value : e.target.value;
                                    time = e.target.value
                                }}/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button type="submit" size="sm">Add New</Button>
                </Form>
            </div>
        </div>);
}
export default ScheduleForms
