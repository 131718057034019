import React, {useState} from "react";
import {SlArrowRightCircle} from "react-icons/sl";
import {Button, Container, Offcanvas} from 'react-bootstrap';
import Navbar from "../Navbar";
function Sidebar() {
    const [show, setShow] = useState(false);
    const closeSidebar = () => setShow(false);
    const showSidebar = () => setShow(true);

    return (
        <>

                <SlArrowRightCircle className="text-dark position-absolute" style={{top:"48vh"}} onClick={showSidebar}/>
                <Offcanvas backdrop='false'  show={show} onHide={closeSidebar}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>NELLIKA KANTHARI</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <Navbar/>
                    </Offcanvas.Body>
                </Offcanvas>

        </>
    );
}

export default Sidebar;