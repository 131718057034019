import Form from "react-bootstrap/Form";
import React from "react";

const SelectBox = (props,ref) => {
    let options = props.options || []
    let keys = props.keys || {label: "label", value: "value"}
    let multiple = props.multiple || false
    let label = props.label || ""
    let name = props.name || ""
    let onselect = props.onChange || function (value, label) {
    }
    let onchange = props.onChange || function (value, label) {
    }
    const onChangeValue = (selector) => {
        let value = selector.options[selector.selectedIndex].text
        onchange(selector.value, value);
    }
    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Select size="sm" name={name} ref={ref}  multiple={multiple} onChange={(e)=>{onChangeValue(e.target)}}>
                <option key={0} value=""> {label} </option>
                {
                    options.map((option, i) => {
                        return <option key={i} value={option[keys.value]}> {option[keys.label]} </option>
                    })
                }
            </Form.Select>
        </Form.Group>
    )
}
export default React.forwardRef(SelectBox);