import {post} from '../../../Services'
import React, {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2'
import SelectBox from "../../inputs/SelectBox";
import TextBox from "../../inputs/TextBox";

const ConfigForms = (props) => {
    const [validated, setValidated] = useState(false);
    const handleConfigMessage = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            setValidated(true);
            return false
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
            }

        }
        let url = '/api/message/conf/'
        let is_post = true
        if (props.data && props.data.id) {
            url = '/api/message/conf/' + props.data.id + "/"
            data["user"] = props.data.user
            is_post = false
        }
        post(url,
            data, is_post, {
                title: 'Success!',
                text: 'Successfully Added Details',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
            }
        )
        setValidated(false);
    }
    return (
        <div className="card p-2 mt-2">
            <div className="container">

                <Form noValidate validated={validated} onSubmit={handleConfigMessage}>

                    <Row className="mb-3">
                        <Col md={4} className="p-1">

                            <TextBox name="template_id"
                                     label="InstanceID/Template ID(You can get from message/ whatapp provider)"/>
                            <TextBox name="auth_key" label="Authentication Key"/>
                            <TextBox name="sender_id" label="Sender ID (Optional)"/>
                        </Col>
                    </Row>
                    <Button type="submit" size="sm">Add New</Button>

                </Form>
            </div>
        </div>

    );
}
export default ConfigForms
