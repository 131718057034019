import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/forms/Login'
import Dashboard from './components/pages/Dashboard'
import Register from './components/forms/Register'
import UserManager from "./components/pages/user/UserManager";
import AssignGroup from "./components/pages/group/AssignGroup";
import Home from './components/pages/home/Home'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import NavbarTop from "./components/NavbarTop";
import axios from "axios";
import React from "react";
import MessageManager from './components/pages/message/Manager';
import ContactForm from './components/pages/message/contact/ContactForm';
import TemplateForm from './components/pages/message/template/TemplateForm';
import ScheduleForm from './components/pages/message/schedule/ScheduleForm';
import ConfigForms from "./components/pages/message/ConfigForms";
import GroupForm from "./components/pages/message/groups/GroupForm";
import ReportForm from './components/pages/message/report/ReportForm';
import UploadForm from "./components/pages/message/upload/UploadForm";
import Enquiry from "./components/pages/enquiry/Enquiry";
import AddPermissions from './components/pages/group/AddPermissions';
import State from "./components/pages/state/State";
import District from "./components/pages/district/District";
import Shops from "./components/pages/shops/Shops";
import Distribute from "./components/pages/distributors/Distribute";
import Feedback from "./components/pages/home/Feedback";
import FeedbackForm from "./components/pages/feedback/FeedbackForm";
import Distributors from "./components/pages/home/Distributors";

function App() {
    // axios.defaults.baseURL = 'http://127.0.0.1:8000/';
    axios.defaults.baseURL = 'https://nellikakanthari.com/';
    return (
        <Router>
            <NavbarTop className="bg-dark px-3 border-bottom"/>
            <Sidebar/>
            <div className="flex-container">
                <div id="main-content">
                    <div className="right m-2">
                        <Routes>
                            <Route exact path="/" element={<Home/>}/>
                            <Route exact path="/dashboard" element={<Dashboard/>}/>
                            <Route exact path="/auth" element={<AssignGroup/>}/>
                            <Route exact path="/login" element={<Login/>}/>
                            <Route exact path="/user_add" element={<Register/>}/>
                            <Route exact path="/authentic" element={<Register/>}/>
                            <Route exact path="/user_details" element={<UserManager/>}/>
                            <Route exact path="/message" element={<MessageManager/>}/>
                            <Route exact path="/contact_add" element={<ContactForm/>}/>
                            <Route exact path="/schedule_add" element={<ScheduleForm/>}/>
                            <Route exact path="/configuration_add" element={<ConfigForms/>}/>
                            <Route exact path="/message_group_add" element={<GroupForm/>}/>
                            <Route exact path="/reports_view" element={<ReportForm/>}/>
                            <Route exact path="/enquiry_add" element={<Enquiry/>}/>
                            <Route exact path="/permission_add" element={<AddPermissions/>}/>
                            <Route exact path="/state_add" element={<State/>}/>
                            <Route exact path="/district_add" element={<District/>}/>
                            <Route exact path="/company_add" element={<Shops/>}/>
                            <Route exact path="/distributors_add" element={<Distribute/>}/>
                            <Route exact path="/feedback" element={<Feedback/>}/>
                            <Route exact path="/feedback_add" element={<FeedbackForm/>}/>
                            <Route exact path="/distributors" element={<Distributors/>}/>

                            <Route exact path="/file_add" element={<UploadForm/>}/>
                            {['/template_add','/template_add_view'].map((path,i)=>{
                                return <Route key={i} exact path={path} element={<TemplateForm/>}/>
                            })}
                            {['/user_add','/user_view'].map((path,i)=>{
                                return <Route key={i} exact path={path} element={<Register/>}/>
                            })}
                            {['/group_add','/group_view'].map((path,i)=>{
                                return <Route key={i} exact path={path} element={<AssignGroup/>}/>
                            })}
                            <Route exact path="/schedule" element={<ScheduleForm/>}/>
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;
