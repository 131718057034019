import {Bar, Line, Chart} from 'react-chartjs-2';
import {Chart as Chartjs} from 'chart.js/auto'

const LineChart = (props) => {
    const labels = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
    ];

    const data = {
        labels: props.x,
        datasets: [{
            label: 'User Join',
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgb(255, 99, 132)',
            data: props.y,
        }]
    };
    const options = {
        scales: {
            x: {
                display: true,
                title: {
                    display: true,
                    text: 'Month',
                }
            },
            y: {
                display: true,
                title: {
                    display: true,
                    text: 'Value',
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: "hello world"
            },
            xAxes: [{
                scaleLabel: {
                    labelString: "Months",
                    display: true
                }
            }]
        }

    }
    const config = {
        type: 'line',
        data: data,
    }

    return (
        <Line
            data={data}
            options={options}
        />
    )
}
export default LineChart