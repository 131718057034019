import Form from "react-bootstrap/Form";
import React from "react";

const FileBox = (props) => {
    const [error, setError] = React.useState('')
    let name = props.name || ""
    let label = props.label || name
    let placeholder = props.placeholder || label ? "Enter " + label : ""
    let message = props.message || placeholder
    let dv = props.defaultValue || ""
    let req = props.required === false ? {} : {"required": true}


    return (
        <Form.Group>
            <Form.Label>{label}</Form.Label>
            <Form.Control
                {...req}
                type="file"
                placeholder={placeholder}
                size="sm"
                name={name}
                defaultValue={dv}
            />
            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>
    )
}
export default FileBox