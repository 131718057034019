import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import useSharedState from "../../../ShareSate";
import TextBox from '../../inputs/TextBox';
import XForm from "../../inputs/XForm";


const AssignGroup = (props) => {
    const {user} = useSharedState()
    const addGroupRef = React.createRef()
    const groupPerRef = React.createRef()
    const formRef = React.createRef()
    const [id, setId] = useState('');

    const [validated, setValidated] = useState(false);
    const [parents, setParents] = useState([])
    const [users, setUsers] = useState([])
    const [groups, setGroups] = useState([])
    const [group, setGroup] = useState([]);
    const [permissions, setPermissions] = useState([])
    const [data, setData] = useState({
        "group": {"include": [], "exclude": []},
        "permission": {"include": [], "exclude": []}
    });
    const [btnValue, setBtnValue] = useState('Add Group');
    const [xdata, setXdata] = useState({});

    const onchange = (value, label) => {
        populateUserGroupPermission(value);
    }
    const populateUser = (id) => {
        get(`/api/user/${id}/`, {}, (resp) => {
            if (resp.status) {
                console.log("USers", resp.data)
                setUsers(resp.data)
            }
        })
    }
    const populateGroup = (id) => {
        get(`/api/group/${id}/`, {}, (resp) => {
            if (resp.status) {
                if (id == 0 && resp.data) {
                    setGroups(resp.data)
                }
            }
        })
    }
    const populateUserGroupPermission = (id) => {
        get(`/api/user/group/permission/${parseInt(id)}/`, {}, (resp) => {
            if (resp.status) {
                console.log("data:", resp.data)
                setData({"group": resp.data.group, "permission": resp.data.permission})
            }
        })
    }
    useEffect(() => {
        populateUser(0)
        getGroup()
    }, []);
    const handleGroup = (event) => {
        setBtnValue("Add Group")
        getGroup();
    }
    const getGroup = () => {
        get("/api/group/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data);
                console.log("group", resp.data);
            }
        });
    }
    const heads = [
        {"name": "Group Name"}
    ]
    const callback = (actionname, data) => {
        if (actionname === "Edit") {
            setXdata(data)
            setBtnValue("Update Group")
        } else if (actionname === "Delete") {
            console.log("delete", actionname);
            del(`api/group/${data.id}`, {}, (resp) => {
                console.log("response", resp);
                getGroup();
            })
        }
    }
    const removeGroup = (actionname, data) => {
        del(`api/user/group/permission/${parseInt(data["id"])}/${groupPerRef.current.value}`, (resp) => {
            populateUserGroupPermission(groupPerRef.current.value);
        })
    }
    return (
        <>
            <div>
                <div>
                    <Container className="g-1">
                        <Card className="mb-2">
                            <Card.Header>Add New Group</Card.Header>
                            <Card.Body>
                                <Row className="g-1">
                                    <Col md={3} className="g-1">
                                        <XForm url="/api/group/" data={xdata} callback={handleGroup}>
                                            <TextBox label="Group Name" name="name"/>
                                            <Button size="sm" type="reset" className="mt-4" onClick={() => {
                                                setId('');
                                                setBtnValue("Add Group")
                                            }}>Clear</Button>
                                            <Button size="sm" type="submit"
                                                    className="mt-4 mx-2 btn-danger">{btnValue}</Button>

                                        </XForm>
                                    </Col>

                                    <Col md={9} className="g-1">

                                        <BasicTable heads={heads} data={group}
                                                    actions={["Edit", "Delete"]}
                                                    actionCallback={callback}/>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row className="g-1">
                            <Col md={12} className="g-1">
                                <XForm url="/api/user/group/permission/" callback={(e, data) => {
                                    populateUserGroupPermission(groupPerRef.current.value)
                                }} hasmulti={["user_id", "groups"]}>
                                    <Card className="mb-2">
                                        <Card.Header>Assign Group To User</Card.Header>
                                        <Card.Body>
                                            <Row className="g-1">
                                                <Col md={3} className="g-1">
                                                    <SearchBox name="user_id" label="Select User" options={users}
                                                               keys={{label: "username", value: "id"}}
                                                               multiple onChange={onchange} ref={groupPerRef} onSelect={(v,l)=>{populateUserGroupPermission(v)}}/>
                                                </Col>

                                                <Col md={3} className="g-1">
                                                    <SearchBox name="groups" label="Assign the Group"
                                                               options={data.group.exclude}
                                                               keys={{label: "name", value: "id"}}
                                                               multiple ref={addGroupRef}/>
                                                    <Button size="sm" type="submit"
                                                            className="mt-4 mx-2 btn-danger">Assign</Button>

                                                </Col>

                                                <Col md={6} className="g-1">
                                                    <h6>Assigned Group (Note: Remove is used to un assigned group from the user)</h6>
                                                    <BasicTable heads={heads} data={data.group.include}
                                                                actions={["Remove"]}
                                                                actionCallback={removeGroup}/>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </XForm>
                            </Col>
                        </Row>
                    </Container>

                </div>
            </div>
            <div>
            </div>

        </>
    );
}
export default AssignGroup
