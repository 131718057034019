import './home.css';
import React, {useState, useEffect} from 'react';
import {get, post, del} from '../../../Services';
import {Card, Row, Col, Container} from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import {AiFillPhone} from "react-icons/ai";
import {Link} from "react-router-dom";
import avtar from './avtar.png';

const Distributors = () => {
    const [distributor, setDistributor] = useState([])

    useEffect(() => {
        getDistributer();
    }, []);

    const getDistributer = () => {
        get("/api/anonydistributor/0/", {}, (resp) => {
            if (resp.status) {
                setDistributor(resp.data);
            }
        });
    }

    const prepareData = (list, type, head, subheads, showNumber) => {
        return (
            list.map((d, i) => {
                if (d.type === type) {
                    return (

                        <div className="btn-group mx-2 my-2"
                             style={{textAlign: "center", display: "inline-block",}}>
                                                                   <span style={{
                                                                       textAlign: "center",
                                                                       "width": "140px",
                                                                       "height": "150px",
                                                                       display: "inline-block",
                                                                       overflowY: "hidden"
                                                                   }}>

                                                                        <img src={d.file}
                                                                             alt="Cinque Terre" style={{width: "140px"}}
                                                                             onError={(currentTarget) => {
                                                                                 currentTarget.onError = null;
                                                                                 currentTarget.src = avtar
                                                                             }}/>

                                                                    </span>
                            <b style={{textTransform:'uppercase', color:"white"}}><div>{d.name}</div>
                            <div style={{textTransform:'uppercase',fontSize:'12px'}}> {head} </div>
                            <div style={{textTransform:'uppercase',fontSize:'12px'}}> {subheads} </div>
                                <div style={{textTransform:'uppercase',fontSize:'11px'}}>{d._district.name}</div></b>
                            {showNumber && <a className="text-primary"
                               href={`tel:${d.contact}`}><AiFillPhone/> {d.contact}</a>}
                        </div>
                    )
                }
            })

        )
    }


    return (
        <Container fluid className="g-2">
            <Link to={"/"}>
                <div className={"badge"}>Back</div>
            </Link>
            <Row className="g-2">
                {
                    prepareData(distributor, "Main", "Managing Director", "Marketing Company",true)
                }
            </Row>
            <Row className="g-2">
                <Col md={12}>
                    <div style={{border: "3px solid white",textAlign:"center"}} className={"my-1"}>
                        <div className={"bg-white"}><b>DIRECTORS</b></div>
                        {
                            prepareData(distributor, "Dealer", "", "Director",true)
                        }
                    </div>
                </Col>
            </Row>
            <Row className="g-2">
                <Col md={12}>
                    <div style={{border: "3px solid white",textAlign:"center"}} className={"my-1"}>
                        <div className={"bg-white"}><b>SUB DEALERS</b></div>
                        {
                            prepareData(distributor, "Sub", "", "Dealer",false)
                        }
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Distributors

