import {post} from '../../Services'
import React, {useState} from 'react';
import axios from "axios";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import useSharedState from '../../ShareSate'
import Card from "react-bootstrap/Card";
import MobileNumber from '../inputs/MobileNumber';
import EmailBox from '../inputs/EmailBox';
import PasswordBox from '../inputs/PasswordBox';
import {Link} from 'react-router-dom';


const Register = (props) => {
    const [validated, setValidated] = useState(false);
    const { setUser} = useSharedState()
    const handleRegister = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false
        }
        
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined) {
                data[form.elements[i].name] = form.elements[i].value
            }

        }

        axios.defaults.headers.common['Authorization'] = ""
        console.log("COrs Hader", axios.defaults.headers)
        console.log("DATA", data)
        post('/api/user/',
            data, true,{
                        title: 'Success!',
                        text: 'Successfully Registered',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    },false,
            (resp) => {
                if (resp.status) {
                    localStorage.lastPath = "/dashboard";
                    setUser(resp.data);
                }
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    return (
        <Card className="m-auto mt-5" style={{width: '50rem'}}>
            <Card.Header>Register Here</Card.Header>
            <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleRegister}>

                    <Row className="mb-3">
                        <Col md={6} className="p-1">
                            <Form.Group controlId="validationCustom01">
                                <Form.Label>First Name </Form.Label>
                                <Form.Control
                                    required
                                    tabIndex={1}
                                    type="text"
                                    placeholder="First Name"
                                    size="sm"
                                    defaultValue=""
                                    name="first_name"

                                />
                                <Form.Control.Feedback type="invalid">Enter First Name</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="validationCustom03">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    required
                                    tabIndex={3}
                                    type="text"
                                    placeholder="Username"
                                    size="sm"
                                    name="username"
                                />
                                <Form.Control.Feedback type="invalid">Required Username </Form.Control.Feedback>
                            </Form.Group>
                            
                            {/* <PasswordBox id="password" onChange={function (value) {
                                setPassword(value)
                            }} Label="Password must contain atleast of 8 characters" placeholder="Enter your Password" tabIndex={5}/> */}
                            <PasswordBox id="password" name="password" Label="Password must contain atleast of 8 characters" placeholder="Enter your Password" tabIndex={5}/>
                            
                            <MobileNumber label="Whatsapp Number(with Country Code)" name="whatsapp_number"
                                          placeholder="Enter your Whatsapp Number" tabIndex={7}/>

                        </Col>
                        <Col md={6} className="p-1">
                            <Form.Group controlId="validationCustom02">
                                <Form.Label>Last Name </Form.Label>
                                <Form.Control
                                    required
                                    tabIndex={2}
                                    type="text"
                                    placeholder="Last Name"
                                    size="sm"
                                    name="last_name"
                                />
                                <Form.Control.Feedback type="invalid">Enter Last Name</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="validationCustom02">
                                <Form.Label>DOB (dd-MM-yyyy)</Form.Label>
                                <Form.Control
                                    required
                                    tabIndex={4}
                                    type="date"
                                    placeholder="Date of birth (00-00-0000)"
                                    size="sm"
                                    name="dob"
                                />
                                <Form.Control.Feedback type="invalid">Date of birth</Form.Control.Feedback>
                            </Form.Group>

                            <PasswordBox id = "confirmpassword" Label="Confirm Password" placeholder="Place confirm your Password"
                                         tabIndex={6}/>
                            {/* <PasswordBox id = "confirmpassword" isConfirming={true} password={password} Label="Confirm Password" placeholder="Place confirm your Password"
                                         tabIndex={6}/> */}

                            <EmailBox label="Email"/>

                        </Col>
                    </Row>
                    <Button type="submit" size="sm" tabIndex={9}>Register</Button>
                    <Link to="/" className="p-1"> Back</Link>
                    

                </Form>
            </Card.Body>
        </Card>
    );
}
export default Register
