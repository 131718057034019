import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import Nav from "react-bootstrap/Nav";
import UserDetails from "../../forms/UserDetails";
import {get, post} from '../../../Services'
import {Row, Col, Container} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import Badge from 'react-bootstrap/Badge';
import SelectBox from "../../inputs/SelectBox";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import ErrorBoundary from "../../errorcatch/ErrorBoundary";
import useSharedState from "../../../ShareSate";
import {forEach} from "react-bootstrap/ElementChildren";

const AddPermissions = (props) => {
    const {user} = useSharedState()
    const addGroupRef = React.createRef()
    const groupPerRef = React.createRef()
    const assignedGroupRef = React.createRef()
    const assignedPerRef = React.createRef()
    const assignedUserRef = React.createRef()
    const parentUserRef = React.createRef()
    const [validated, setValidated] = useState(false);
    const [view, setView] = useState(localStorage.group_view);
    const [parents, setParents] = useState([])
    const [users, setUsers] = useState([])
    const [groups, setGroups] = useState([])
    const [permissions, setPermissions] = useState([])
    const [data, setData] = useState({
        "group": {"include": [], "exclude": []},
        "permission": {"include": [], "exclude": []}
    });


    const changeView = (state) => {
        localStorage.group_view = state
        setView(state)
    }
    const onchange = (value, label) => {
        populateUserGroupPermission(value);
        let _users = parents.filter((user) => {
            return user.username !== label
        })
        setUsers(_users)
    }
    const populateUser = (id) => {
        get(`/api/user/${id}/`, {}, (resp) => {
            if (resp.status) {
                console.log("users:", data)
                if (id == 0 && data["users"] !== resp.data) {
                    setParents(resp.data)
                }
            }
        })
    }
    const populateGroup = (id) => {
        get(`/api/group/${id}/`, {}, (resp) => {
            if (resp.status) {
                if (id == 0 && resp.data) {
                    setGroups(resp.data)
                }
            }
        })
    }
    const populateUserGroupPermission = (id) => {
        get(`/api/user/group/permission/${id}/`, {}, (resp) => {
            if (resp.status) {
                setData({"group": resp.data.group, "permission": resp.data.permission})
            }
        })
    }
    const handleGroupPermission = (event) => {
        const form = event.currentTarget;
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
                if (form.elements[i].tagName === 'SELECT') {
                    data[form.elements[i].name] = Array.from(form.elements[i].selectedOptions, (option) => option.value)
                }
            }

        }
        post("/api/user/group/permission/",
            data, true,{
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    },false,
            (resp) => {
                console.log("output:", resp.data)
                if (resp.status) {
                    setData({"group": resp.data.group, "permission": resp.data.permission})
                }
            }
        )
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);

    }
    const handleRemoveGroup = (event) => {
        let assignedUsers = Array.from(assignedUserRef.current.selectedOptions, (option) => option.value)
        let assignedGroups = Array.from(assignedGroupRef.current.selectedOptions, (option) => option.value)
        console.log("users", assignedUsers)
        console.log("groups", assignedGroups)
        let data = {"user_id": assignedUsers, "groups": assignedGroups, "operation": "removegroup"}
        post("/api/user/group/permission/",
            data, true,
            (resp) => {
                console.log("output:", resp.data)
                if (resp.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                    setData({"group": resp.data.group, "permission": resp.data.permission})
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Error occured please do after  sometime' + resp.status,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }

            }
        )
        event.preventDefault();
        event.stopPropagation();
        return false
    }
    const handleUserPermission = (event) => {
        let parentUsers = Array.from(parentUserRef.current.selectedOptions, (option) => option.value)
        let assignedUsers = Array.from(assignedUserRef.current.selectedOptions, (option) => option.value)
        let assignedPer = Array.from(assignedPerRef.current.selectedOptions, (option) => option.value)
        console.log("parent", parentUsers)
        console.log("users", assignedUsers)
        console.log("permis", assignedPer)
        let data = {"parents": parentUsers, "users": assignedUsers, "permissions": assignedPer}
        post("/api/useruserpermission/",
            data, true,
            (resp) => {
                console.log("output:", resp.data)
                if (resp.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                    setData({"group": resp.data.group, "permission": resp.data.permission})
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Error occured please do after  sometime' + resp.status,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }

            }
        )
        event.preventDefault();
        event.stopPropagation();
        event.preventDefault();
        event.stopPropagation();
        return false
    }
    useEffect(() => {
        populateUser(0);
        populateGroup(0);

    }, [data.users]);
    useEffect(() => {
        setPermissions(user.permissions.filter((per) => {
            return per.startsWith('auth.')
        }))
        changeView(localStorage.group_view)
    }, [])
    return (
        <div>                
            <div>
                <Form name="assigngroup" noValidate validated={validated} onSubmit={handleGroupPermission}>
                    <Container className="g-1">
                        <Row className="g-1">
                            <Col md={8} className="g-1">
                                <SearchBox name="user_id" label="Search User" options={parents}
                                           keys={{label: "username", value: "id"}}
                                           multiple onChange={onchange} ref={assignedUserRef}/>
                            </Col>
                        </Row>

                        <Row className="g-1">
                            <Col md={4}>

                                <div md={4} className="pt-2">
                                    <SelectBox name="assigned_groups" ref={assignedGroupRef} label="Assigned Groups"
                                               multiple
                                               options={data.group.include}
                                               keys={{label: "name", value: "id"}}/>
                                    <Button onClick={handleRemoveGroup} size="sm" className="mt-2">Remove
                                        Groups</Button>
                                </div>

                                <div md={4} className="pt-2">
                                    <SelectBox name="assigned_permission" label="Assigned Permission" multiple
                                               options={data.permission.include}
                                               keys={{label: "name", value: "id"}}/>
                                    <Button size="sm" className="mt-2">Remove Permission</Button>
                                </div>

                            </Col>
                            <Col md={4} className="g-1">
                                <SearchBox name="groups" label="Select Group" options={data.group.exclude}
                                           keys={{label: "name", value: "id"}}
                                           multiple/>
                                <SearchBox name="permissions" label="Select Permissions"
                                           options={data.permission.exclude}
                                           keys={{label: "name", value: "id"}}
                                           multiple/>
                                <Button size="sm" className="mt-2" type="submit">Save</Button>
                            </Col>

                        </Row>

                    </Container>
                </Form>


            </div>
        </div>

    );
}
export default AddPermissions
