import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import useSharedState from "../../../ShareSate";
import TextBox from '../../inputs/TextBox';
import XForm from "../../inputs/XForm";
import SelectBox from "../../inputs/SelectBox";

const Shops = (props) => {
    const formRef = React.createRef()
    const [id, setId] = useState('');
    const [validated, setValidated] = useState(false);
    const [district, setDistrict] = useState([])
    const [shop, setShop] = useState([])
    const [filered, setFilered] = useState([])
    const [btnValue, setBtnValue] = useState('Add Shop');
    const [xdata, setXdata] = useState({});
    const [url, setUrl] = useState("");
    let ischange = false
    useEffect(() => {
        getDistrict();
        getShop();
    }, [ischange]);
    const handleSubmits = (event, data) => {
        setBtnValue("Add Shop")

        getShop();
    }
    const getDistrict = () => {
        get("/api/district/0/", {}, (resp) => {
            if (resp.status) {
                setDistrict(resp.data);
            }
        });
    }
    const getShop = () => {
        get("/api/company/0/", {}, (resp) => {
            if (resp.status) {
                console.log("OK",localStorage.district)
                setShop(resp.data);
                filtering(localStorage.district)
            }
        });
    }

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        }
    }

    function showPosition(position) {
        let x = position.coords.latitude +
            ", " + position.coords.longitude;
        setXdata({"location": x});
        let u = `https://www.google.com/maps/@${x}`
        setUrl(u)
    }

    const heads = [
        {"name": "Shop Name",},
        {"contact": "Shop Contact"},
        {"_district.name": "District"},
        {"place": "Place"},
        {"address": "Address"}
    ]
    const callback = (actionname, data) => {
        if (actionname === "Edit") {
            setXdata(data)
            setBtnValue("Update Shop");
        } else if (actionname === "Delete") {
            del(`api/company/${data.id}`, (resp) => {
                console.log("response", resp);
            });
        }
        ischange = !ischange
        getShop()
    }
    const filtering = (value)=>{
        let filered = []
        shop.map((s, i) => {
            if (s.district == parseInt(value)) {
                filered.push(s)
            }
        })
        setFilered(filered)
    }
    const onChange = (value, text) => {
        localStorage.district = value
        filtering(value)

    }

    return (
        <>
            <div>
                <div>
                    <Container className="g-1">
                        <Card className="mb-2">
                            <Card.Header>Add Shop Name</Card.Header>
                            <Card.Body>
                                <Row className="g-1">
                                    <Col md={3} className="g-1">
                                        <XForm url="/api/company/" data={xdata} callback={handleSubmits} saveBtn={"Save Shop"} updateBtn={"Update Shop"}>
                                            <SelectBox name="district" options={district}
                                                       keys={{label: "name", value: "id"}} onChange={(v, l) => {
                                                onChange(v, l)
                                            }}/>
                                            <TextBox label="Shop Name" name="name"/>
                                            <TextBox label="Shop Contact" name="contact" required={false}/>
                                            <TextBox label="Shop Place" name="place"/>
                                            <TextBox label="Shop Address" name="address"/>
                                            <TextBox label="Shop location get or enter geocode from google map"
                                                     name="location"/>
                                            <Button size="sm" type="reset" className="mt-4" onClick={() => {
                                                setId('');
                                                setBtnValue("Add Shop")
                                            }}>Clear</Button>
                                            <Button size="sm" type="submit"
                                                    className="mt-4 mx-2 btn-danger">{btnValue}</Button>

                                        </XForm>
                                    </Col>

                                    <Col md={9} className="g-1 py-4 overflow-y overflow-x" style={{maxHeight: '80vh'}}>

                                        <BasicTable heads={heads} data={filered}
                                                    actions={["Edit", "Delete"]}
                                                    actionCallback={callback}/>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>
            </div>
            <div>
            </div>

        </>
    );
}
export default Shops
