import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import useSharedState from "../../../ShareSate";
import TextBox from '../../inputs/TextBox';
import XForm from "../../inputs/XForm";


const District = (props) => {
    const formRef = React.createRef()
    const [id, setId] = useState('');
    const [validated, setValidated] = useState(false);
    const [states, setStates] = useState([])
    const [btnValue, setBtnValue] = useState('Add Enquiry');
    const [xdata, setXdata] = useState({});

    useEffect(() => {
        getState();
    }, []);
    const handleSubmits = (event,data) => {
        setBtnValue("Add District")
        getState();
    }
    const getState = () => {
        get("/api/district/0/", {}, (resp) => {
            if (resp.status) {
                setStates(resp.data);
            }
        });
    }
    const heads = [
        {"name": "District Name"},
        {"contact": "District Contact"}
    ]
    const callback = (actionname, data) => {
        if (actionname === "Edit") {
            setXdata(data)
            setBtnValue("Update District")
            getState();
        } else if (actionname === "Delete") {
            console.log("delete", actionname);
            del(`api/district/${data.id}`,  (resp) => {
                console.log("response", resp);
                getState();
            });
        }
    }
    return (
        <>
            <div>
                <div>
                    <Container className="g-1">
                        <Card className="mb-2">
                            <Card.Header>Add District</Card.Header>
                            <Card.Body>
                                <Row className="g-1">
                                    <Col md={3} className="g-1">
                                        <XForm url="/api/district/" data={xdata} callback={handleSubmits}>

                                            <TextBox label="District Name" name="name"/>
                                            <TextBox label="Distributer Contact" name="contact"/>
                                            <Button size="sm" type="reset" className="mt-4" onClick={() => {
                                                setId('');
                                                setBtnValue("Add Enquiry")
                                            }}>Clear</Button>
                                            <Button size="sm" type="submit"
                                                    className="mt-4 mx-2 btn-danger">{btnValue}</Button>

                                        </XForm>
                                    </Col>

                                    <Col md={9} className="g-1 py-4">

                                        <BasicTable heads={heads} data={states}
                                                    actions={["Edit", "Delete"]}
                                                    actionCallback={callback}/>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>
            </div>
            <div>
            </div>

        </>
    );
}
export default District
