import Carousel from 'react-bootstrap/Carousel';

function CarosalEx(props) {
    return (

        <Carousel>
            {props.imgs.map((img,i)=>{
                return <Carousel.Item key={i}>
                    <img className="d-block w-100" src={img.src} alt="First slide"/>
                </Carousel.Item>
            })}
        </Carousel>
    );
}

export default CarosalEx;