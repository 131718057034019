import Navbar from "../../../Navbar";
import {Card, Container, Row, Col, Button, Nav} from "react-bootstrap";
import TextBox from "../../../inputs/TextBox";
import MobileNumber from "../../../inputs/MobileNumber";
import BasicTable from "../../../views/BasicTable";
import React, {useEffect, useState} from "react";
import DateTimeBox from "../../../inputs/DateTimeBox";
import FileUpload from "../../../fileupload/FileUpload";
import {get, post} from "../../../../Services";
import useSharedState from "../../../../ShareSate";
import Form from "react-bootstrap/Form";
import SelectBox from "../../../inputs/SelectBox";
import SearchBox from "../../../inputs/SearchBox";

const ContactForm = (props) => {//ES6
    const [validated, setValidated] = useState(false);
    const [contact, setConatct] = useState([]);
    const [group, setGroup] = useState([]);
    const [date_label, setDate_label] = useState('Date');
    const {setUser} = useSharedState()
    useEffect(() => {
        getContacts();
        getGroup();
    }, [])
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false;
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value;
            }
        }

        post('/api/message/contact/',
            data, true, {
                title: 'Success!',
                text: 'Contact data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
                if (resp.status) {
                    console.log("datafrom server", resp.data)
                    contact.push(resp.data)
                    setConatct(contact)
                }
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const handleContactDetails = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false;
        }
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value;
            }
        }
        console.log("data", data)
        post('/api/message/contact/details/',
            data, true, {
                title: 'Success!',
                text: 'Contact data updated',
                icon: 'success',
                confirmButtonText: 'OK'
            }, false,
            (resp) => {
            })
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }
    const getContacts = () => {
        get("/api/message/contact/0/", {}, (resp) => {
            if (resp.status) {
                console.log("contats", resp.data)
                setConatct(resp.data)
            }
        })
    }
    const getGroup = () => {
        get("/api/message/group/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data)
            }
        })

    }
    const onChangeType = (e) => {
        let label = e.target.options[e.target.selectedIndex].text
        setDate_label("Select Date for " + label)
    }
    const heads = [
        {"name": "Name"}, {"number": "Number"}, {"details._date": "Date"}, {"details.type": "Message Type"}
    ]
    const callback = (h, g) => {
    }
    return (
        <>
            <Container className="g-1">
                <Row className="g-1">
                    <Col md={6} className="g-1">
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Card className="mb-2">
                                <Card.Header>Contacts</Card.Header>
                                <Card.Body>
                                    <Row className="g-1">
                                        <Col md={6} className="g-1">
                                            <TextBox label="Contact Name" name="name"/>
                                            <MobileNumber label="Contact Number" name="number"/>
                                            <SearchBox label="Select Groups" multiple={true}/>
                                            <Button size="sm" type="submit" className="mt-4">Submit</Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                    <Col md={6} className="g-1">
                        <Form noValidate validated={validated} onSubmit={handleContactDetails}>
                            <Card className="mb-2">
                                <Card.Header>Contact Details</Card.Header>
                                <Card.Body>
                                    <SelectBox name="contact" label="Contact"
                                               options={contact}
                                               keys={{label: "name", value: "id"}}/>
                                    <SelectBox name="type"
                                               label="Type Ex: Birth Day, Anniversary, if not found add to group"
                                               options={group}
                                               keys={{label: "group_name", value: "id"}} onChange={onChangeType}/>
                                    <DateTimeBox name="_date" label={date_label}/>
                                    <TextBox label="Description" name="category"/>
                                    <Button size="sm" type="submit" className="mt-4">Submit</Button>
                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>

                </Row>
            </Container>


            <Card>
                <Card.Header className="p-1">
                    <Nav>
                        <Nav className="me-auto px-2"> All Contacts</Nav>
                        <Nav><Button size="sm" className="mx-1">Insert a new field</Button><Button
                            size="sm" className="">Delete a field</Button></Nav>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    <BasicTable heads={heads} data={contact}
                                actions={["View more..", "Edit", "Delete"]}
                                actionCallback={callback}/>
                </Card.Body>
            </Card>
        </>
    )


}
export default ContactForm