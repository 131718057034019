import {post, get} from '../../../Services'
import React, {useState, useEffect} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2'
import SelectBox from "../../inputs/SelectBox";
import TextBox from "../../inputs/TextBox";
import TextArea from "../../inputs/TextArea";
import SearchBox from "../../inputs/SearchBox";

const TemplateForms = (props) => {
    const [validated, setValidated] = useState(false);
    const [conf, setConf] = useState([]);
    const [group, setGroup] = useState([]);
    const [user, setUser] = useState([]);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false
        }
        console.log(form.elements[0].value)
        console.log(form.elements[0].name)
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
            }

        }
        console.log("sending group data:", data)
        let url = '/api/messagetemplate/'
        let is_post = true
        if (props.data.id) {
            url = '/api/messagetemplate/' + props.data.id + "/"
            data["user"] = props.data.user
            is_post = false
        }

        post(url,
            data, is_post,
            (resp) => {
                console.log("output:", resp)
                if (resp.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Error occured please do after  sometime' + resp.status,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }

    const getConfig = () => {
        get("/api/messageconf/0/", {}, (resp) => {
            if (resp.status) {
                setConf(resp.data)
                console.log("config", conf)
            }
        })

    }
    const getGroup = () => {
        get("/api/groupmessage/0/", {}, (resp) => {
            if (resp.status) {
                setGroup(resp.data)
            }
        })

    }
    const getUser = () => {
        get("/api/user/0/", {}, (resp) => {
            if (resp.status) {
                setUser(resp.data)
            }
        })

    }
    useEffect(() => {
        getConfig()
        getGroup()
        getUser()
    }, [])
    return (
        <div className="card p-2 mt-2">
            <div className="container">

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <Row className="mb-3">
                        <Col md={4} className="p-1">
                            <SelectBox name="group" label="Wishing Type/(Group Name)"
                                       options={group}
                                       keys={{label: "group_name", value: "id"}}/>

                            <SelectBox name="conf" label="Configuration"
                                       options={conf}
                                       keys={{label: "template_id", value: "id"}}/>

                            <TextArea name="content" label="Content"/>
                        </Col>
                        <Col md={4} className="p-1">
                            <SearchBox name="users" className="mt-1" label="Select/Enter User" options={user}
                                       keys={{label: "first_name", value: "id"}}
                                       multiple/>
                        </Col>
                    </Row>
                    <Button type="submit" size="sm">Add New</Button>

                </Form>
            </div>
        </div>

    );
}
export default TemplateForms
