import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import {FcCheckmark} from "react-icons/fc";

function BasicTable(props) {
    const headings = props.heads || []
    const datas = props.data || []
    const actions = props.actions || false
    const actionWidth = props.actionWidth ? props.actionWidth : "18%"
    const nestedValue = function (obj, keys) {
        let value = obj
        keys.split(".").forEach((key) => {
            value = value[key]
        })
        return value
    };
    const heads = headings.map((head, i) => {
        let rkey = "h_" + i
        let key = Object.keys(head)[0]
        return <th key={rkey}>{head[key]}</th>
    })
    const colors = ["warning", "info", "danger"]

    const rows = datas.map((data, i) => {
        let rokey = "r_" + i
        return <tr key={rokey}>
            <th>{i + 1}</th>
            {headings.map((head, i) => {
                let rkey = "d_" + i
                let key = Object.keys(head)[0]
                if (props.replacer && nestedValue(data, key) && props.replacer[key]) {
                    return <td key={rkey}>{props.replacer[key][0]}</td>
                }
                if (props.replacer && nestedValue(data, key) == false && props.replacer[key]) {
                    return <td key={rkey}>{props.replacer[key][1]}</td>
                }
                return <td key={rkey}>{nestedValue(data, key)}</td>
            })}
            {actions.length !== 0 && <td width={actionWidth}>
                {actions.map((action, i) => {

                    // return <span key={i} className={colors[i] + " p-2 badge"} onClick={()=>{props.actionCallback(action,data)}}>{action}</span>
                    return <Badge key={i} bg={colors[i]} className="m-1 cursor-pointer" onClick={() => {
                        props.actionCallback(action, data)
                    }}>{action}</Badge>
                })}
            </td>}
        </tr>
    })

    return (
        <Table striped bordered hover>
            <thead>
            <tr>
                <th>#</th>
                {heads}
                {actions.length !== 0 && <th>Action</th>}
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    );
}

export default BasicTable;