import {Link} from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import useSharedState from "../ShareSate";
import {useNavigate, useLocation} from 'react-router-dom';
import React, {useEffect} from "react";
import {BsPower} from 'react-icons/bs'
import Login from '../components/forms/Login'

const Navbar = (props) => {
    const {user, setUser, isLogged} = useSharedState()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const allowed = ["/login", "/user_add"]
        if (!isLogged && location) {
            if ((allowed.filter(element => element === location.pathname).length === 0)) {
                navigate("/");
            }
        } else {
            if (!localStorage.lastPath) localStorage.lastPath = "/dashboard"
            navigate(localStorage.lastPath)
            console.log("USER:", user);
        }
    }, [location.pathname])
    const onChangeNav = (selectedKey, actions) => {
        if (isLogged) {
            localStorage.lastPath = selectedKey
        }
        if (selectedKey === "/logout") {
            setUser({})
            navigate("/")
            localStorage.lastPath = "/dashboard";
        }
    }
    return (
        <Nav defaultActiveKey="/home" className="flex-column">
            <div className="overflow-y mb-2" style={{height: '72vh'}}>
                {!isLogged && <Login/>}
                <hr/>
                {isLogged && <Link key={908} to="/dashboard" className="nav-item nav-link"
                                   onClick={(e) => {
                                       onChangeNav(("/dashboard"))
                                   }}>Dashboard</Link>}
                {Object.keys(user.nav).map((key, i) => {
                    let _label = key
                    if (key === 'auth') {
                        _label = 'Group & Permissions'
                    }
                    if (key === 'authentic') {
                        _label = 'User Management'
                    }
                    return (<NavDropdown key={i} title={_label} style={{textTransform: 'capitalize'}}>
                        {user.nav[key].map((sub, j) => {
                            return (<>{ Object.keys(sub).map((su_key, k) => {
                                return sub[su_key].map((action, l) => {
                                    if (action==='add' || action==='view') {
                                        let label_ = action + " " + (su_key.indexOf("_")?su_key.replace("_"," "):su_key)
                                        let path = su_key + "_" + action
                                        return (
                                            <Link key={i+" "+ j +""+l} to={path}
                                                  style={{textTransform: 'capitalize'}}
                                                  className="nav-link" onClick={(e) => {
                                                onChangeNav(("/" + path), sub[su_key])
                                            }}>{label_}</Link>
                                        )
                                    }
                                });
                            })}
                            <Dropdown.Divider key={i+" "+ j}/>
                            </>
                            )
                        })}
                    </NavDropdown>)
                })}

                {isLogged && <Link key={902} to="/logout" className="nav-link text-danger text-center" onClick={(e) => {
                    onChangeNav("/logout")
                }}>
                    Logout <BsPower/>
                </Link>}
            </div>


        </Nav>
    )
}
export default Navbar
