import React, {useState, useEffect} from 'react';
import Button from "react-bootstrap/Button";
import BasicTable from "../../views/BasicTable";
import {get, post, del} from '../../../Services'
import {Card, Row, Col, Container} from 'react-bootstrap'
import SearchBox from "../../inputs/SearchBox";
import useSharedState from "../../../ShareSate";
import TextBox from '../../inputs/TextBox';
import FileBox from '../../inputs/FileBox.js';
import XForm from "../../inputs/XForm";
import SelectBox from "../../inputs/SelectBox";
import TextArea from "../../inputs/TextArea";

const FeedbackForm = (props) => {
    const formRef = React.createRef()
    const [id, setId] = useState('');
    const [validated, setValidated] = useState(false);
    const [feedback, setFeedback] = useState([])
    const [shop, setShop] = useState([])
    const [btnValue, setBtnValue] = useState('Add Feedback');
    const [xdata, setXdata] = useState({});
    const [url, setUrl] = useState("");
    let old = {}

    useEffect(() => {
        getFeedback();
    }, []);
    const handleSubmits = (event, data) => {
        setBtnValue("Add Feedback")
    }
    const getFeedback = () => {
        get("/api/feedback/0/", {}, (resp) => {
            if (resp.status) {
                setFeedback(resp.data);
            }
        });
    }

    const heads = [
        {"name": "Name",},
        {"category": "Category",},
        {"description": "Description"},
        {"file": "File"},
    ]
    const callback = (actionname, data) => {
        if (actionname === "Edit") {
            setXdata(data)
            setBtnValue("Update Feedback");
            getFeedback()
        } else if (actionname === "Delete") {
            console.log("delete", actionname);
            del(`api/feedback/${data.id}`, (resp) => {
                console.log("response", resp);
                getFeedback();
            });
        }
    }
    return (
        <>
            <div>
                <div>
                    <Container className="g-1">
                        <Card className="mb-2">
                            <Card.Header>Feedback.</Card.Header>
                            <Card.Body>
                                <Row className="g-1">
                                    <Col md={3} className="g-1">
                                        <XForm url="/api/feedback/" data={xdata} hasFile={true} callback={handleSubmits}>
                                            <SelectBox name="category" label="Select Category" options={[{"label":"Audio","value":"audio"}]} keys={{label: "label", value: "value"}}/>
                                            <TextArea label="Your Description" name="description"/>
                                            <FileBox label="Upload Audio" name="file" type="file"/>
                                            <Button size="sm" type="submit"
                                                    className="mt-4 mx-2 btn-danger">{btnValue}</Button>
                                        </XForm>
                                    </Col>

                                    <Col md={9} className="g-1 py-4 overflow-y" style={{maxHeight:'80vh'}}>

                                        <BasicTable heads={heads} data={feedback}
                                                    actions={["Edit", "Delete"]}
                                                    actionCallback={callback}/>

                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Container>

                </div>
            </div>
            <div>
            </div>

        </>
    );
}
export default FeedbackForm
