import {post} from '../../Services'
import {useState} from 'react';
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2'

const UserDetails = (props) => {
    const [validated, setValidated] = useState(false);
    const handleRegister = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
            return false
        }
        console.log(form.elements[0].value)
        console.log(form.elements[0].name)
        let data = {}
        for (let i in form.elements) {
            if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
                data[form.elements[i].name] = form.elements[i].value
            }

        }
        let url = '/api/user_details/'
        let is_post = true
        if (props.data.id) {
            url = '/api/user_details/' + props.data.id + "/"
            data["user"] = props.data.user
            is_post = false
        }

        post(url,
            data, is_post,
            (resp) => {
                console.log("output:", resp)
                if (resp.status) {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Successfully updated user details',
                        icon: 'success',
                        confirmButtonText: 'OK'
                    })
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'Error occured please do after  sometime' + resp.status,
                        icon: 'error',
                        confirmButtonText: 'OK'
                    })
                }
            }
        )
        setValidated(false);
        event.preventDefault();
        event.stopPropagation();
    }

    // sexual_history = models.CharField(max_length=100)
    // major_complaints = models.CharField(max_length=100)
    // major_recommentations = models.CharField(max_length=100)
    return (
        <div className="container">
            <Form noValidate validated={validated} onSubmit={handleRegister}>

                <Row className="mb-3">
                    <Col md={4} className="p-1">
                        <Form.Group controlId="validationCustom01">
                            <Form.Label>House Name </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="First Name"
                                size="sm"
                                defaultValue={props.data.house_name}
                                name="house_name"
                            />
                            <Form.Control.Feedback type="invalid">Enter First Name</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom03">
                            <Form.Label>Educational Qualification</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Educational Qualification"
                                size="sm"
                                as="textarea"
                                name="educational_qualification"
                                defaultValue={props.data.educational_qualification}
                            />
                            <Form.Control.Feedback type="invalid">Required Username </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom05">
                            <Form.Label>Marital Status</Form.Label>
                            <Form.Select size="sm" required name="maritalstatus" defaultValue={props.data.maritalstatus}>
                                <option value={1}>Male</option>
                                <option value={2}>FeMale</option>
                                <option value={3}>Other</option>
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">Required Mobile </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom05">
                            <Form.Label>Childhood History</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Childhood History"
                                size="sm"
                                as="textarea"
                                name="childhood_history"
                                defaultValue={props.data.childhood_history}
                            />
                            <Form.Control.Feedback type="invalid">Required Mobile </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom05">
                            <Form.Label>Sexual History</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Sexual History"
                                size="sm"
                                as="textarea"
                                name="sexual_history"
                                defaultValue={props.data.sexual_history}
                            />
                            <Form.Control.Feedback type="invalid">Required Mobile </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom05">
                            <Form.Label>Major Recommentations</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Major Recommentations"
                                size="sm"
                                as="textarea"
                                name="major_recommentations"
                                defaultValue={props.data.major_recommentations}
                            />
                            <Form.Control.Feedback type="invalid">Required Mobile </Form.Control.Feedback>
                        </Form.Group>

                    </Col>
                    <Col md={4} className="p-1">
                        <Form.Group controlId="validationCustom02">
                            <Form.Label>Place </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Last Name"
                                size="sm"
                                name="place"
                                defaultValue={props.data.place}

                            />
                            <Form.Control.Feedback type="invalid">Enter Last Name</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom04">
                            <Form.Label>Job</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Job"
                                size="sm"
                                name="job"
                                defaultValue={props.data.job}

                            />
                            <Form.Control.Feedback type="invalid">Required password </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom06">
                            <Form.Label>Social Economic Status</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Social Economic Status"
                                size="sm"
                                as="textarea"
                                name="social_economic_status"
                                defaultValue={props.data.social_economic_status}
                            />
                            <Form.Control.Feedback type="invalid">Required Whatapp Number</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom06">
                            <Form.Label>Psychological History</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                as="textarea"
                                placeholder="Psychological History"
                                size="sm"
                                name="psychological_history"
                                defaultValue={props.data.psychological_history}
                            />
                            <Form.Control.Feedback type="invalid">Required Whatapp Number</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom06">
                            <Form.Label>Physiological History</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                as="textarea"
                                placeholder="Physiological History"
                                size="sm"
                                name="physiological_history"
                                defaultValue={props.data.physiological_history}
                            />
                            <Form.Control.Feedback type="invalid">Required Whatapp Number</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="validationCustom06">
                            <Form.Label>Major Complaints</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                as="textarea"
                                placeholder="Major Complaints"
                                size="sm"
                                name="major_complaints"
                                defaultValue={props.data.major_complaints}
                            />
                            <Form.Control.Feedback type="invalid">Required Whatapp Number</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Button type="submit" size="sm">Register</Button>

            </Form>
        </div>

    );
}
export default UserDetails
